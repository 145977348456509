import { Button, Row } from 'react-bootstrap'
import styles from '../../Register.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from 'react';
import UnitModal from './UnitModal';
import UnitList from '../UnitList';

export default function UnitComponent(){
 
  const [showModal, setShowModal] = useState(false);
  const [reloadUnitList, setReloadUnitList] = useState(false); 
  const keyRef = useRef(0); // Referência para a chave única do UnitList

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReloadUnitList(true); 
  };

  const handleUnitListReloaded = () => {
    setReloadUnitList(false); 
  };

  const toggleKey = () => {
    keyRef.current = keyRef.current === 0 ? 1 : 0; // Alternando entre 0 e 1
  };

  return(
    <div className="mt-5">
      <Row>
        <h1 className={`${styles.titleContent} display-6`}>Controle de Unidades</h1>
      </Row>

      <Button className={`${styles.buttonAdd} mt-5`} onClick={handleShowModal}>
        <FontAwesomeIcon icon={faAdd} />
      </Button>

      <div className="container">
        {/* Adicionando a chave única dinâmica e passando a função de recarregamento como prop */}
        <UnitList key={`${reloadUnitList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handleUnitListReloaded} />
      </div>

      {/* Renderize o modal condicionalmente */}
      {showModal && <UnitModal onClose={handleCloseModal} />}

    </div>
  )
}