import { useEffect, useState } from 'react';
import { Modal, Button, Form, Alert, Row, Col } from 'react-bootstrap';
import Select, { ActionMeta, SingleValue } from 'react-select';
import requestClient from '../../../../requestClient/request-cliente'; // Certifique-se de que o caminho está correto

interface BankModalProps {
  onClose: () => void;
}

interface Bank {
  ispb: string;
  name: string;
  code: number;
  fullName: string;
}

const BankModal: React.FC<BankModalProps> = ({ onClose }) => {
  const [bankName, setBankName] = useState('');
  const [bankCode, setBankCode] = useState('');
  const [banks, setBanks] = useState<Bank[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (bankName) {
      const fetchFilteredBanks = async () => {
        try {
          const response = await fetch(`http://webapi.gfttech.com.br/api/portal/bank-data/consult?str=${bankName}`);
          const filteredBanks = await response.json();
          setBanks(filteredBanks);
        } catch (error) {
          console.error('Error fetching filtered bank data:', error);
          setErrorMessage('Erro ao buscar os dados do banco.');
        }
      };

      fetchFilteredBanks();
    } else {
      setBanks([]);
    }
  }, [bankName]);

  const handleSelectChange = (newValue: SingleValue<Bank>, actionMeta: ActionMeta<Bank>) => {
    if (newValue) {
      const selectedBank = newValue as Bank;
      setBankName(selectedBank.name);
      setBankCode(selectedBank.code.toString());
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('POST', 'http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/cadastrar', { name: bankName, number_bank: bankCode }, headers);

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Banco cadastrado com sucesso.');
        setBankName('');
        setBankCode('');
      } else {
        setErrorMessage('Erro ao cadastrar o banco. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Erro ao cadastrar o banco. Por favor, tente novamente.');
    }
  };

  return (
    <Modal show={true} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Criar novo Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Row>
              <Col xs={8}>
                <Form.Control
                  type="text"
                  placeholder="Nome do Banco"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  required
                />
              </Col>
              <Col xs={4}>
                <Form.Control
                  type="text"
                  placeholder="Nº Banco"
                  value={bankCode}
                  onChange={(e) => setBankCode(e.target.value)}
                  required
                />
              </Col>
            </Row>
          </Form.Group>
          {banks.length > 0 && (
            <Select
              options={banks}
              getOptionLabel={(bank) => `${bank.name} (${bank.code})`}
              placeholder="Selecione o Banco"
              className='mt-3'
              getOptionValue={(bank) => bank?.code?.toString() ?? ''}
              onChange={handleSelectChange as (newValue: SingleValue<Bank>, actionMeta: ActionMeta<Bank>) => void}
            />
          )}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}
          <div className="d-flex justify-content-end mt-5">
            <Button variant="secondary" onClick={onClose} className="me-2">
              Fechar
            </Button>
            <Button variant="primary" type="submit">
              Salvar
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default BankModal;
