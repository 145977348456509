import { useState, useEffect } from "react";
import axios from "axios";
import { Form, FormGroup, FormLabel } from "react-bootstrap";

interface Department {
  department_id: number;
  department_name: string;
}

interface Role {
  role_id: number;
  role_name: string;
}

interface Position {
  position_id: number;
  position_name: string;
}

interface CreationParametersProps {
  formData: any; // Alteração para receber a prop formData
  onFormDataChange: (newFormData: any) => void;
}

const CreationParameters: React.FC<CreationParametersProps> = ({ formData, onFormDataChange }) => {
  const [departments, setDepartments] = useState<Department[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [positions, setPositions] = useState<Position[]>([]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get<Department[]>("http://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/buscar-todos", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setDepartments(response.data ?? []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get<Role[]>("http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar-todos",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoles(response.data ?? []);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    const fetchPositions = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get<Position[]>("http://webservices.gfttech.com.br/api/v1/posicao/buscar-todas",{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPositions(response.data ?? []);
      } catch (error) {
        console.error("Error fetching positions:", error);
      }
    };

    fetchDepartments();
    fetchRoles();
    fetchPositions();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    const newFormData = {
      ...formData,
      [name]: value,
    };
    onFormDataChange(newFormData);
  };

  return (
    <>
      <FormGroup className="mt-5">
        <FormLabel>Departamento</FormLabel>
        <Form.Select aria-label="" name="department_id" onChange={handleChange} value={formData.department_id}>
          <option value="">Selecione um departamento</option>
          {departments.map((department) => (
            <option key={department.department_id} value={department.department_id}>
              {department.department_name}
            </option>
          ))}
        </Form.Select>
      </FormGroup>
      <FormGroup>
        <FormLabel>Cargo</FormLabel>
        <Form.Select aria-label="" name="position_id" onChange={handleChange} value={formData.position_id}>
          <option value="">Selecione um cargo</option>
          {positions.map((position) => (
            <option key={position.position_id} value={position.position_id}>
              {position.position_name}
            </option>
          ))}
        </Form.Select>
      </FormGroup>
      <FormGroup>
        <FormLabel>Função</FormLabel>
        <Form.Select aria-label="" name="role_id" onChange={handleChange} value={formData.role_id}>
          <option value="">Selecione uma função</option>
          {roles.map((role) => (
            <option key={role.role_id} value={role.role_id}>
              {role.role_name}
            </option>
          ))}
        </Form.Select>
      </FormGroup>
    </>
  );
};

export default CreationParameters;
