import { Container } from "react-bootstrap";

import styles from "../../../../styles/contentTemplate.module.scss"
import NavBar from "../../../../components/NavBar";

export default function HomeGecom(){
  return(
    <>
      <Container fluid className="p-0" style={{overflowX: 'hidden'}}>
        <section className={styles.navbarSection}>
          <NavBar/>
        </section>
        <section className={styles.contentSection}>
          
        </section>
      </Container>
    </>
  )
}