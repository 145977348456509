import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditRoleModalProps {
  RoleId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const RoleEditModal: React.FC<EditRoleModalProps> = ({ RoleId, onClose, onUpdate, onError }) => {
  const [RoleName, setRoleName] = useState<string>('');
  const [DescriptionRole, setDescriptionRole] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (RoleId) {
      fetchRoleName();
    }
  }, [RoleId]);

  const fetchRoleName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !RoleId) {
        throw new Error('Token or Role ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar/${RoleId}`, null, headers);

      if (response.status === 201) {
        setRoleName(response.result.role.role_name);
        setDescriptionRole(response.result.role.description);
      } else {
        throw new Error('Erro ao buscar o nome da Função. Por favor, tente novamente.');
      }
    } catch (error: any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome da Função. Por favor, tente novamente.');
    }
  };

  const handleRoleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleName(event.target.value);
  };

  const handleDescriptionRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescriptionRole(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !RoleId) {
        throw new Error('Token or Role ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/atualizar/${RoleId}`, 
        { 
          role_name: RoleName, 
          description: DescriptionRole 
        }, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true);
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar o Função. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar o Função. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar o Função. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Função</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Função atualizada com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formRoleName">
              <Form.Label>Nome da Função</Form.Label>
              <Form.Control type="text" placeholder="Digite o nome da Função" value={RoleName} onChange={handleRoleNameChange} required />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formDescriptionRole">
              <Form.Label>Descrição</Form.Label>
              <Form.Control type="text" placeholder="Digite a descrição" value={DescriptionRole} onChange={handleDescriptionRoleChange} required />
            </Form.Group>
          </Row>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default RoleEditModal;