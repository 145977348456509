import React from 'react';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import styles from './ModalApprove.module.scss';
import ButtonPrimary from '../../../ButtonPrimary';
import ButtonSecondary from '../../../ButtonSecondary';

interface ModalApproveProps {
  show: boolean;
  onHide: () => void;
  onApprove: () => void;
}

const ModalApprove: React.FC<ModalApproveProps> = ({ show, onHide, onApprove }) => {
  return (
    <Modal show={show} onHide={onHide} className={styles.customModal}>
      <Modal.Header closeButton className={styles.backgroundModal}>
        <Modal.Title className={styles.modalTitle}>Confirmar Aprovação</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles.backgroundModal} ${styles.bodyModal}`}>
        <FloatingLabel controlId="Observacao" label="Observação">
          <Form.Control
            as="textarea"
            placeholder="Observação"
            style={{ height: '100px' }}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer className={styles.backgroundModal}>
        <ButtonSecondary content="Cancelar" onClick={onHide}/>
        <ButtonPrimary content="Aprovar" onClick={onApprove}/>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalApprove;