import imageNotFound from '../../../assets/images/msg_id_not_found.png'
import styles from './NotFoundPartner.module.scss'

export default function NotFoundPartner() {
  return (
    <div className={styles.notFoundContainer}>
      <img src={imageNotFound} className={styles.imageNotFound} alt="Not Found" />
    </div>
  )
}
