import { useEffect, useState } from 'react';
import { Modal, Tab, Nav, Table, Spinner, Container } from 'react-bootstrap';
import styles from './SpecialConditions.module.scss';

interface SpecialConditionsProps {
  show: boolean;
  handleClose: () => void;
  codProdutorVenda: number;
}

interface CondicaoEspecifica {
  banco: string;
  operacao: string;
  produto: string;
  inicioVig: string;
  finalVig: string;
  pcl_acordo: number;
}

interface CondicaoGeral {
  banco: string;
  dsc_operacao: string;
  convenio: string;
  inicioVig: string;
  finalVig: string;
  pcl_acordo: number;
}

export default function SpecialConditions({ show, handleClose, codProdutorVenda }: SpecialConditionsProps) {
  const [activeTab, setActiveTab] = useState('especifica');
  const [especificaData, setEspecificaData] = useState<CondicaoEspecifica[]>([]);
  const [geralData, setGeralData] = useState<CondicaoGeral[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (show) {
      setLoading(true);
      const fetchData = async () => {
        try {
          const [especificaResponse, geralResponse] = await Promise.all([
            fetch(`http://bi.gfttech.com.br/api/v1/atendimento/condicoesEspeciaisEspecificas?codProdutorVenda=${codProdutorVenda}`),
            fetch(`http://bi.gfttech.com.br/api/v1/atendimento/condicoesEspeciaisGerais?codProdutorVenda=${codProdutorVenda}`)
          ]);

          if (especificaResponse.status === 200) {
            const especificaData = await especificaResponse.json();
            setEspecificaData(especificaData);
          } else {
            console.error('Erro ao buscar dados específicos:', especificaResponse.status);
          }

          if (geralResponse.status === 200) {
            const geralData = await geralResponse.json();
            setGeralData(geralData);
          } else {
            console.error('Erro ao buscar dados gerais:', geralResponse.status);
          }
        } catch (error) {
          console.error('Erro ao buscar dados:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [show, codProdutorVenda]);

  if (loading) {
    return <Spinner animation="border" />;
  }

  const navLinkStyle = (tabName: string) => ({
    backgroundColor: activeTab === tabName ? '#1B096B' : 'transparent',
    color: activeTab === tabName ? 'white' : '#1B096B',
    fontWeight: activeTab === tabName ? 'bold' : 'normal'
  });

  return (
    <Modal show={show} onHide={handleClose} size="lg" className={styles.modalStyles}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4 className={styles.headerConditionsList}>
            Condições Especiais
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles.conditionsBackgroundModal}>
        <Container>
          <Tab.Container id="left-tabs-example" activeKey={activeTab}>
            <Nav variant="tabs" className="mt-4 mb-4">
              <Nav.Item>
                <Nav.Link
                  eventKey="especifica"
                  onClick={() => setActiveTab('especifica')}
                  style={navLinkStyle('especifica')}
                >
                  Condições Específicas
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="geral"
                  onClick={() => setActiveTab('geral')}
                  style={navLinkStyle('geral')}
                >
                  Condições Gerais
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content className={styles.tabContent}>
              <Tab.Pane eventKey="especifica">
                <Table className='mt-5 mb-5' bordered>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Operação</th>
                      <th>Produto</th>
                      <th>Início da Vigência</th>
                      <th>Final da Vigência</th>
                      <th>PCL Acordo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {especificaData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.banco}</td>
                        <td>{item.operacao}</td>
                        <td>{item.produto}</td>
                        <td>{new Date(item.inicioVig).toLocaleDateString('pt-BR')}</td>
                        <td>{new Date(item.finalVig).toLocaleDateString('pt-BR')}</td>
                        <td>{item.pcl_acordo}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey="geral">
                <Table className='mt-5 mb-5' bordered>
                  <thead>
                    <tr>
                      <th>Banco</th>
                      <th>Operação</th>
                      <th>Convênio</th>
                      <th>Início da Vigência</th>
                      <th>Final da Vigência</th>
                      <th>PCL Acordo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {geralData.map((item, index) => (
                      <tr key={index}>
                        <td>{item.banco}</td>
                        <td>{item.dsc_operacao}</td>
                        <td>{item.convenio}</td>
                        <td>{new Date(item.inicioVig).toLocaleDateString('pt-BR')}</td>
                        <td>{new Date(item.finalVig).toLocaleDateString('pt-BR')}</td>
                        <td>{item.pcl_acordo}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Container>
      </Modal.Body>
    </Modal>
  );
}