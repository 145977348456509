import msgFinishImage from '../../../assets/images/Msg_Pre_Cadastro_Concluido.png';
import styles from './FinalMessage.module.scss';

export default function FinalMessage() {

  const handleRedirect = () => {
    window.location.href = '/login';
  };

  return (
    <>
    <div className={styles.finalMessageContainer}>
      <img src={msgFinishImage} className={styles.finishImage} alt="Final Message" />
    </div>
    <button className={`btn btn-primary ${styles.redirectButton}`} onClick={handleRedirect}>
        Ir para Login
      </button>
    </>
  );
}
