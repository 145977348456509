import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faPhone, faHome, faBriefcase, faFileAlt, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import styles from './ReviewDataExportEmployee.module.scss';

interface DataExportModalProps {
  show: boolean;
  handleClose: () => void;
  handleExport: (selectedColumns: string[]) => void;
}

type Topics = {
  [key: string]: string[];
};

type Icons = {
  [key: string]: any;
};

const topics: Topics = {
  "Dados Pessoais": ["Nome", "CPF", "RG", "Dt_Nascimento", "Genero", "Estado_Civil","Formacao","Nome_Pai", "Nome_Mae"],
  "Financeiro": ["Banco", "Agencia","N_Conta","Tipo_Conta","Chave_Pix"],
  "Endereço": ["CEP", "Rua", "Numero", "Complemento", "Bairro", "Cidade", "Estado"],
  "Profissional": ["Filial", "Departamento", "Cargo", "Funcao", "Dt_Contratacao", "Dt_Desligamento", "Tipo_Contrato", "Salario"],
  "Contato": ["Telefone", "Celular", "Email"],
  "Documentos": ["CTPS", "PIS"],
};

const icons: Icons = {
  "Dados Pessoais": faUser,
  "Contato": faPhone,
  "Endereço": faHome,
  "Profissional": faBriefcase,
  "Financeiro": faDollarSign,
  "Documentos": faFileAlt,
};

const DataExportModal: React.FC<DataExportModalProps> = ({ show, handleClose, handleExport }) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  // Use useEffect to set all columns selected initially
  useEffect(() => {
    const initialSelected: string[] = Object.values(topics).reduce((acc, columns) => [...acc, ...columns], []);
    setSelectedColumns(initialSelected);
  }, []);

  const handleTopicChange = (topic: string) => {
    const topicColumns = topics[topic];
    const isSelected = topicColumns.every(col => selectedColumns.includes(col));

    if (isSelected) {
      setSelectedColumns(prevState => prevState.filter(col => !topicColumns.includes(col)));
    } else {
      setSelectedColumns(prevState => [...prevState, ...topicColumns.filter(col => !prevState.includes(col))]);
    }
  };

  const handleCheckboxChange = (column: string) => {
    setSelectedColumns(prevState =>
      prevState.includes(column)
        ? prevState.filter(col => col !== column)
        : [...prevState, column]
    );
  };

  const handleExportClick = () => {
    handleExport(selectedColumns);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" dialogClassName="custom-modal">
      <Modal.Header closeButton>
        <Modal.Title>Selecionar Colunas para Exportação</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className={styles.topicsContainer}>
            {Object.keys(topics).map(topic => (
              <div key={topic} className={styles.topicColumn}>
                <Form.Check
                  type="checkbox"
                  label={<span><FontAwesomeIcon icon={icons[topic]} /> {topic}</span>}
                  checked={topics[topic].every(col => selectedColumns.includes(col))}
                  onChange={() => handleTopicChange(topic)}
                  className={styles.topicCheckbox}
                />
                <div className={styles.columnsContainer}>
                  {topics[topic].map(column => (
                    <Form.Check
                      key={column}
                      type="checkbox"
                      label={column}
                      checked={selectedColumns.includes(column)}
                      onChange={() => handleCheckboxChange(column)}
                      className={styles.columnCheckbox}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleExportClick}>
          Exportar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataExportModal;