import { Button, Row } from 'react-bootstrap'
import styles from '../../Register.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { useRef, useState } from 'react';
import FunctionModal from './RoleModal';
import RoleList from '../RoleList';
import RoleModal from './RoleModal';

export default function FunctionComponent(){
 
  const [showModal, setShowModal] = useState(false);
  const [reloadRoleList, setReloadPositionList] = useState(false); 
  const keyRef = useRef(0); // Referência para a chave única do PositionList

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReloadPositionList(true); 
  };

  const handleRoleListReloaded = () => {
    setReloadPositionList(false); 
  };

  const toggleKey = () => {
    keyRef.current = keyRef.current === 0 ? 1 : 0; // Alternando entre 0 e 1
  };
  return(
    <div className="mt-5">
      <Row>
        <h1 className={`${styles.titleContent} display-6`}>Controle de Funções</h1>
      </Row>

      <Button className={`${styles.buttonAdd} mt-5`} onClick={handleShowModal}>
        <FontAwesomeIcon icon={faAdd} />
      </Button>

      <div className="container">
        {/* Adicionando a chave única dinâmica e passando a função de recarregamento como prop */}
        <RoleList key={`${reloadRoleList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handleRoleListReloaded} />
      </div>

      {showModal && <RoleModal onClose={() => {handleCloseModal(); toggleKey();}} />}
    </div>
  );
}