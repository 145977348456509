import { Button, Container } from 'react-bootstrap';
import NavBar from '../../components/NavBar';
import styles from './AccessManagement.module.scss';
import stylesTemplate from '../../styles/contentTemplate.module.scss';
import CustomTable from '../../components/CustomTable';
import { useState, useEffect } from 'react';
import FadeInComponent from '../../components/FadeInEffect/FadeInTransition';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsonData from '../../data/data.json';
import IData from '../../interfaces/IDataCadastro';
import Pagination from '../../components/Pagination';

export default function AccessManagementPartner() {
  const headers = ['ID', 'Nome', 'Login', 'Departamento', 'Função', 'Status'];
  const itemsPerPage = 7;

  const [currentPage, setCurrentPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [allData, setAllData] = useState<IData[]>([]);

  useEffect(() => {
    setAllData(jsonData);
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = allData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setInitialLoad(false);
  };

  return (
    <>
      <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
        <section className={styles.navbarSection}>
          <NavBar/>
        </section>
        {initialLoad && (
          <FadeInComponent>
            <section className={`${styles.contentSection} container`}>
              <h5 className={`${styles.mainTitle} display-5 mt-5 mb-5 pt-5`}>Controle de Acessos - Parceiros</h5>
              <Button className={`${stylesTemplate.buttonDefault} mb-4`}>
                <FontAwesomeIcon icon={faAdd} />
              </Button>
              <CustomTable headers={headers} data={currentData} />
              <Pagination
                totalItems={allData.length}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </section>
          </FadeInComponent>
        )}
        {!initialLoad && (
          <section className={`${styles.contentSection} container`}>
            <h4 className={`${styles.mainTitle} display-5 mt-5 mb-5`}>Acessos/Parâmetros - Parceiros</h4>
            <CustomTable headers={headers} data={allData} />
            <Pagination
              totalItems={allData.length}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </section>
        )}
      </Container>
    </>
  );
}
