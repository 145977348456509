import React, { useState, useEffect } from 'react';
import { Col, Button, Card, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import styles from '../../AccessManagementEmployee.module.scss';

interface ParamsUserDetails {
  department_id: number;
  role_id: number;
}

interface Option {
  id: number;
  name: string;
}

const EditParamsUser: React.FC<{ id: string }> = ({ id }) => {
  const [paramsUserDetails, setParamsUserDetails] = useState<ParamsUserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [departments, setDepartments] = useState<Option[]>([]);
  const [roles, setRoles] = useState<Option[]>([]);

  useEffect(() => {
    const fetchParamsUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || !id) {
          throw new Error('Token or User ID not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/departamento-papel/buscar-informacoes/${id}`, null, headers);

        if (response.status === 200) {
          setParamsUserDetails(response.result.DepartmentAcces);
        } else {
          setError('Erro ao buscar os detalhes do usuário. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os detalhes do usuário. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    const fetchOptions = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const [departmentsResponse, rolesResponse] = await Promise.all([
          requestClient('get', 'http://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/buscar-todos', null, headers),
          requestClient('get', 'http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar-todos', null, headers),
        ]);

        if (departmentsResponse.status === 200 && rolesResponse.status === 200) {
          const departmentsData = departmentsResponse.result.map((department: any) => ({
            id: department.department_id,
            name: department.department_name,
          }));
          const rolesData = rolesResponse.result.map((role: any) => ({
            id: role.role_id,
            name: role.role_name,
          }));
          setDepartments(departmentsData);
          setRoles(rolesData);
        } else {
          setError('Erro ao buscar as opções de departamentos e papéis. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar as opções de departamentos e papéis. Por favor, tente novamente.');
      }
    };

    fetchParamsUserDetails();
    fetchOptions();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setParamsUserDetails((prevState) => ({
      ...prevState!,
      [name]: parseInt(value),
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !id) {
        throw new Error('Token or User ID not found');
      }

      const data = {
        department_id: paramsUserDetails?.department_id,
        role_id: paramsUserDetails?.role_id,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/departamento-papel/atualizar/${id}`, JSON.stringify(data), headers);

      if (response.status === 200) {
        alert('Parâmetros de usuário atualizados com sucesso');
        window.location.reload();
      } else {
        setError('Erro ao atualizar os parâmetros do usuário. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao atualizar os parâmetros do usuário. Por favor, tente novamente.');
    }
  };

  return (
    <Col className='mt-5' sm={12} md={12}>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className={`${styles.mainTitle} display-5`}>Parâmetros de Usuário</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group controlId="formDepartmentId">
                  <Form.Label>Departamento</Form.Label>
                  <Form.Select
                    name="department_id"
                    value={paramsUserDetails?.department_id || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione o Departamento</option>
                    {departments.map((department) => (
                      <option key={department.id} value={department.id}>
                        {department.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group controlId="formRoleId" className='mt-3'>
                  <Form.Label>Papel</Form.Label>
                  <Form.Select
                    name="role_id"
                    value={paramsUserDetails?.role_id || ''}
                    onChange={handleInputChange}
                  >
                    <option value="">Selecione o Papel</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </Button>
          </div>
          {error && <p className="text-danger mt-3">{error}</p>}
        </>
      )}
    </Col>
  );
};

export default EditParamsUser;
