import { Button, Container } from 'react-bootstrap';
import NavBar from '../../components/NavBar';
import styles from './AccessManagementEmployee.module.scss';
import stylesTemplate from '../../styles/contentTemplate.module.scss';
import { useState, useEffect, useRef } from 'react';
import FadeInComponent from '../../components/FadeInEffect/FadeInTransition';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import jsonData from '../../data/data.json';
import IData from '../../interfaces/IDataCadastro';
import CreateEmployeeModal from '../../components/Register/CreateEmployeeModal';
import EmployeeList from '../../components/Register/CreateEmployeeModal/EmployeeList';

export default function AccessManagementEmployee() {
  const headers = ['ID', 'Nome', 'Login', 'Departamento', 'Função', 'Status'];
  const itemsPerPage = 7;

  const [currentPage, setCurrentPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [allData, setAllData] = useState<IData[]>([]);
  const [reloadEmployeeList, setReloadEmployeeList] = useState(false); 
  const keyRef = useRef(0);

  useEffect(() => {
    setAllData(jsonData);
  }, []);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = allData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setInitialLoad(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReloadEmployeeList(true);
  };
 
  const handleEmployeeListReloaded = () => {
    setReloadEmployeeList(false); 
  };

  const toggleKey = () => {
    keyRef.current = keyRef.current === 0 ? 1 : 0;
  };


  return (
    <>
      <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
        <section className={styles.navbarSection}>
          <NavBar/>
        </section>
        {initialLoad && (
          <FadeInComponent>
            <section className={`${styles.contentSection} container`}>
              <h5 className={`${styles.mainTitle} display-5 mt-5 mb-5 pt-5`}>Controle de Acessos - Funcionários</h5>
              <Button className={`${stylesTemplate.buttonDefault} mb-4`} onClick={handleShowModal}>
                <FontAwesomeIcon icon={faAdd} />
              </Button>
              <EmployeeList key={`${reloadEmployeeList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handleEmployeeListReloaded}/>
            </section>
          </FadeInComponent>
        )}
        {!initialLoad && (
          <section className={`${styles.contentSection} container`}>
            <h4 className={`${styles.mainTitle} display-5 mt-5 mb-5`}>Acessos/Parâmetros - Funcionários</h4>
            <EmployeeList key={`${reloadEmployeeList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handleEmployeeListReloaded}/>
          </section>
          
        )}
        
        {/* Renderize o modal condicionalmente */}
        {showModal && <CreateEmployeeModal onClose={handleCloseModal} />}
      </Container>
    </>
  );
}
