import React, { ReactNode, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';

interface FadeInProps {
  children: ReactNode;
}

const FadeInComponent: React.FC<FadeInProps> = ({ children }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []); // excuta apenas na montagem do componente
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 500 }, // config da animação
    reset: fadeIn, // reseta a animação se fadeIn mudar
    reverse: !fadeIn, // inverte a animação se fadeIn mudar
  });

  return <animated.div style={props}>{children}</animated.div>;
};

export default FadeInComponent;
