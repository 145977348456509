
// src/components/ButtonPrimary.tsx
import React from 'react';
import styles from './ButtonLight.module.scss';
import { Button } from 'react-bootstrap';

interface ButtonLightProps {
  content: any;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonLight: React.FC<ButtonLightProps> = ({ content, onClick, disabled }) => {
  return (
    <Button
      className={`btn ${styles.buttonLight}`}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </Button>
  );
};

export default ButtonLight;