import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import styles from './Spinner.module.scss';

interface SpinnerDefaultProps {
  size?: string; // Define the size as a string (e.g., '2rem', '4rem')
}

const SpinnerDefault: React.FC<SpinnerDefaultProps> = ({ size = '3rem' }) => {
  return (
    <div
      className={styles.customSpinner}
      style={{ '--spinner-size': size } as React.CSSProperties} // Set the size
    >
      <Spinner animation="border" className={styles.customSpinner} />
    </div>
  );
};

export default SpinnerDefault;
