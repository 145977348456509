import { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import axios from 'axios';
import ValidateCode from '../ValidateCode';

interface FormPreRegistrationProps {
  codManager?: string;
}

const validarCPF = (cpf: string) => {
    // remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    // calcula o primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    let digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

    // verifica o primeiro dígito verificador
    if (parseInt(cpf.charAt(9)) !== digitoVerificador1) {
        return false;
    }

    // calcula o segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    let digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

    // verifica o segundo dígito verificador
    if (parseInt(cpf.charAt(10)) !== digitoVerificador2) {
        return false;
    }

    // CPF válido
    return true;
};

const FormPreRegistration: React.FC<FormPreRegistrationProps> = ({ codManager }) => {
    const [formData, setFormData] = useState({
        contractType: '',
        partnerName: '',
        corporateName: '',
        tradeName: '',
        cnpj: '',
        cpf: '',
        rg: '',
        email: '',
        phone: '',
        address: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        cep: '',
        codManager: codManager || '',
    });

    const [cepData, setCepData] = useState({
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: ''
    });

    const [cepValid, setCepValid] = useState(true);
    const [cepLoading, setCepLoading] = useState(false);
    const [cnpjLoading, setCnpjLoading] = useState(false);
    const [cnpjValid, setCnpjValid] = useState(true);
    const [cpfValido, setCpfValido] = useState(true);
    const [emailValido, setEmailValido] = useState(true);
    const [cpfExistente, setCpfExistente] = useState(false);
    const [emailExistente, setEmailExistente] = useState(false);    
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (formData.contractType === 'Parceiro PJ' && formData.cnpj.length === 18) {
            handleCnpjValidation(formData.cnpj);
        }
    }, [formData.contractType, formData.cnpj]);

    const handleChange = (e: ChangeEvent<any>) => {
      const { name, value } = e.target;
  
      // Verifica se é o campo tradeName e o tipo de contrato é Parceiro PJ
      if (name === 'tradeName' && formData.contractType === 'Parceiro PJ') {
          setFormData(prevFormData => ({
              ...prevFormData,
              [name]: value,
              partnerName: value
          }));
      } else {
          setFormData(prevFormData => ({
              ...prevFormData,
              [name]: value,
              ...(name === 'contractType' && value !== prevFormData.contractType && {
                  corporateName: '',
                  tradeName: '',
                  cnpj: '',
                  cpf: '',
                  rg: '',
                  email: '',
                  phone: '',
                  address: '',
                  street: '',
                  number: '',
                  neighborhood: '',
                  city: '',
                  state: '',
                  cep: ''
              })
          }));
      }
  };

    const handleCepChange = async (cep: string) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            cep: cep
        }));

        if (cep.length === 9) {
            setCepLoading(true);
            try {
                const response = await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
                const data = response.data;
                if (data.cep) {
                    setCepData({
                        street: data.street,
                        number: data.number,
                        neighborhood: data.neighborhood,
                        city: data.city,
                        state: data.state
                    });
                    setCepValid(true);
                } else {
                    setCepValid(false);
                }
            } catch (error) {
                console.error('Error fetching CEP data:', error);
                setCepValid(false);
            } finally {
                setCepLoading(false);
            }
        } else {
            setCepValid(false);
        }
    };

    const handleCnpjValidation = async (cnpj: string) => {
        try {
            setCnpjLoading(true);
            const formattedCnpj = cnpj.replace(/[^\d]/g, '');
            const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${formattedCnpj}`);
            const { data, status } = response;
            if (status === 200) {
                setFormData({
                    ...formData,
                    corporateName: data.razao_social,
                    tradeName: '',
                    phone: data.ddd_telefone_1,
                    street: data.logradouro,
                    number: data.numero,
                    neighborhood: data.bairro,
                    city: data.municipio,
                    state: data.uf,
                    cep: data.cep
                });
                setCepData({
                    street: data.logradouro,
                    number: data.numero,
                    neighborhood: data.bairro,
                    city: data.municipio,
                    state: data.uf
                });
                setCnpjValid(true);
            } else {
                setCnpjValid(false);
            }
        } catch (error) {
            console.error('Error fetching CNPJ data:', error);
            setCnpjValid(false);
        } finally {
            setCnpjLoading(false);
        }
    };

   
    const handleCpfBlur = () => {
        const cpfIsValid = validarCPF(formData.cpf);
        setCpfValido(cpfIsValid);
        if (cpfIsValid) {
            setFormData(prevFormData => ({
                ...prevFormData,
                cpf: formData.cpf
            }));
        } else {
            
        }
    };


    const handleEmailBlur = async () => {
      const email = formData.email;
      const emailIsValid = validarEmail(email);
      setEmailValido(emailIsValid);
    
      if (emailIsValid) {
        try {
          const response = await fetch(`http://webservices.gfttech.com.br/api/v1/verificar-dados-parceiro?email=${email}`);
          const data = await response.json();
          
          // Verificar se o valor de 'email' é true
          setEmailExistente(data.email === true);
        } catch (error) {
          setEmailExistente(false);
        }
      } else {
        setEmailExistente(false);
      }
    };
    


    const handleCpfChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const cpf = e.target.value;
      setFormData(prevFormData => ({ ...prevFormData, cpf }));

      // Validar CPF em tempo real
      const cpfLimpo = cpf.replace(/[^\d]/g, '');
      const cpfIsValid = cpfLimpo.length === 11 ? validarCPF(cpf) : false;
      setCpfValido(cpfIsValid);

      if (cpfIsValid) {
          try {
              const response = await fetch(`http://webservices.gfttech.com.br/api/v1/verificar-dados-parceiro?cpf=${cpf}`);
              const data = await response.json();
              
              // Verificar se o valor de 'cpf' é true
              setCpfExistente(data.cpf === true);
              console.log(data.cpf)
          } catch (error) {
              setCpfExistente(false);

          }
      } else {
          // Se o CPF não for válido, considere que ele não existe
          setCpfExistente(false);
      }
    };

    const validarEmail = (email: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };
      

    const handleEmailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setFormData(prevFormData => ({ ...prevFormData, email }));

        // Validar e-mail em tempo real
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValido(emailRegex.test(email));

        if (emailRegex.test(email)) {
            try {
                const response = await fetch(`http://webservices.gfttech.com.br/api/v1/verificar-dados-parceiro?email=${email}`);
                const data = await response.json();
                
                // Verificar se o valor de 'email' é true
                setEmailExistente(data.email === true);
            } catch (error) {
                setEmailExistente(false);
            }
        } else {
            // Se o e-mail não for válido, considere que ele não existe
            setEmailExistente(false);
        }
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        // Verificar CPF
        if (!validarCPF(formData.cpf)) {
            setCpfValido(false);
            setLoading(false);
            return;
        } else {
            setCpfValido(true);
        }

        // Verificar e-mail
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setEmailValido(false);
            setLoading(false);
            return;
        } else {
            setEmailValido(true);
        }

        try {
            // Verificar se CPF já existe
            let response = await fetch(`http://webservices.gfttech.com.br/api/v1/verificar-dados-parceiro?cpf=${formData.cpf}`);
            const data = await response.json();
              if (data.cpf === true) {
                  setCpfExistente(true);
                  return;
              } else {
                  setCpfExistente(false);
              }

            // Verificar se e-mail já existe
            response = await fetch(`http://webservices.gfttech.com.br/api/v1/verificar-dados-parceiro?email=${formData.email}`);
            if (data.email === true) {
                setEmailExistente(true);
                setLoading(false);
                return;
            } else {
                setEmailExistente(false);
            }

            // Enviar token por e-mail
            response = await fetch('http://webservices.gfttech.com.br/api/v1/email-enviar-token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: formData.email }),
            });

            if (!response.ok) {
                throw new Error('Failed to send email token');
            }

            console.log("Código enviado com sucesso");
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error sending email token:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleBack = () => {
      setIsSubmitted(false);
    };

    return (
      <>
        {!isSubmitted ? (
          <Form onSubmit={handleSubmit} className="mt-4">
             {loading ? (
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
            ) : (
            <>
              <Row>
                  <Col md={12}>
                      <div className="form-floating mb-3">
                          <Form.Select
                              name="contractType"
                              value={formData.contractType}
                              onChange={(e: ChangeEvent<HTMLSelectElement>) => handleChange(e)}
                              id="floatingContractType"
                              required
                          >
                              <option value="">Selecione o Tipo de Contrato</option>
                              <option value="Parceiro PF">Parceiro PF</option>
                              <option value="Parceiro PJ">Parceiro PJ</option>
                          </Form.Select>
                          <Form.Label htmlFor="floatingContractType">Tipo de Contrato</Form.Label>
                      </div>
                  </Col>
              </Row>

              {formData.contractType === 'Parceiro PF' && (
                  <>
                      <Row className="mt-4">
                          <Col md={12}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="partnerName"
                                      value={formData.partnerName}
                                      onChange={handleChange}
                                      id="floatingPartnerName"
                                      placeholder="Nome do Parceiro"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingPartnerName">Nome do Parceiro</Form.Label>
                              </div>
                          </Col>
                      </Row>
                      
                      <Row>
                          <Col md={6}>
                            <div className="form-floating mb-3">
                                <Form.Control
                                    as={IMaskInput}
                                    mask="000.000.000-00"
                                    type="text"
                                    name="cpf"
                                    value={formData.cpf}
                                    onChange={handleCpfChange}
                                    onBlur={handleCpfBlur}
                                    id="floatingCpf"
                                    placeholder="CPF"
                                    required
                                    className={`form-control ${(!cpfValido && formData.cpf.length === 14) || cpfExistente ? 'is-invalid' : ''}`}

                                />
                                <Form.Label htmlFor="floatingCpf">CPF</Form.Label>
                                {!cpfValido && <div className="invalid-feedback">CPF inválido</div>}
                                {cpfExistente && <div className="invalid-feedback">CPF já cadastrado</div>}
                            </div>
                          </Col>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="rg"
                                      value={formData.rg}
                                      onChange={handleChange}
                                      id="floatingRg"
                                      placeholder="RG"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingRg">RG</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={6}>
                            <div className="form-floating mb-3">
                              <Form.Control
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleEmailChange}
                                onBlur={handleEmailBlur}
                                id="floatingEmail"
                                placeholder="E-mail"
                                required
                                className={`form-control ${!emailValido || emailExistente ? 'is-invalid' : ''}`}
                              />
                              <Form.Label htmlFor="floatingEmail">E-mail</Form.Label>
                              {!emailValido && <div className="invalid-feedback">E-mail inválido</div>}
                              {emailExistente && <div className="invalid-feedback">E-mail já cadastrado</div>}
                            </div>

                          </Col>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control 
                                      as={IMaskInput}
                                      mask="(00) 00000-0000"
                                      type="text"
                                      name="phone"
                                      value={formData.phone}
                                      onChange={handleChange}
                                      id="floatingPhone"
                                      placeholder="Telefone"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingPhone">Telefone</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={12}>
                              <div className="form-floating mb-3">
                                  <IMaskInput
                                      mask="00000-000"
                                      type="text"
                                      name="cep"
                                      value={formData.cep}
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleCepChange(e.target.value)}
                                      id="floatingCep"
                                      placeholder="CEP"
                                      required
                                      className={`form-control ${!cepValid && 'is-invalid'}`}
                                  />
                                  <Form.Label htmlFor="floatingCep">CEP</Form.Label>
                                  {!cepValid && <div className="invalid-feedback">Informe um CEP válido</div>}
                                  {cepLoading && <div>Consultando CEP...</div>}
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={10}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="street"
                                      value={cepData.street}
                                      onChange={handleChange}
                                      id="floatingStreet"
                                      placeholder="Rua"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingStreet">Rua</Form.Label>
                              </div>
                          </Col>
                          <Col md={2}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="number"
                                      value={cepData.number}
                                      onChange={handleChange}
                                      id="floatingNumber"
                                      placeholder="N°"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingNumber">N°</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={4}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="neighborhood"
                                      value={cepData.neighborhood}
                                      onChange={handleChange}
                                      id="floatingNeighborhood"
                                      placeholder="Bairro"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingNeighborhood">Bairro</Form.Label>
                              </div>
                          </Col>
                          <Col md={4}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="city"
                                      value={cepData.city}
                                      onChange={handleChange}
                                      id="floatingCity"
                                      placeholder="Cidade"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingCity">Cidade</Form.Label>
                              </div>
                          </Col>
                          <Col md={4}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="state"
                                      value={cepData.state}
                                      onChange={handleChange}
                                      id="floatingState"
                                      placeholder="Estado"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingState">Estado</Form.Label>
                              </div>
                          </Col>
                          
                      </Row>
                  </>
              )}

              {formData.contractType === 'Parceiro PJ' && (
                  <>
                      <Row className="mt-4">
                          <Col md={12}>
                              <div className="form-floating mb-3">
                                  <IMaskInput
                                      mask="00.000.000/0000-00"
                                      type="text"
                                      name="cnpj"
                                      value={formData.cnpj}
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
                                      id="floatingCnpj"
                                      placeholder="CNPJ"
                                      required
                                      className={`form-control ${!cnpjValid && 'is-invalid'}`}
                                  />
                                  <Form.Label htmlFor="floatingCnpj">CNPJ</Form.Label>
                                  {!cnpjValid && <div className="invalid-feedback">Informe um CNPJ válido</div>}
                                  {cnpjLoading && <div>Consultando CNPJ...</div>}
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="corporateName"
                                      value={formData.corporateName}
                                      onChange={handleChange}
                                      id="floatingCorporateName"
                                      placeholder="Razão Social"
                                      required
                                      readOnly
                                  />
                                  <Form.Label htmlFor="floatingCorporateName">Razão Social</Form.Label>
                              </div>
                          </Col>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="tradeName"
                                      value={formData.tradeName}
                                      onChange={handleChange}
                                      id="floatingTradeName"
                                      placeholder="Nome Fantasia"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingTradeName">Nome Fantasia</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="email"
                                      name="email"
                                      value={formData.email}
                                      onChange={handleChange}
                                      id="floatingEmail"
                                      placeholder="E-mail"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingEmail">E-mail</Form.Label>
                              </div>
                          </Col>
                          <Col md={6}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      as={IMaskInput}
                                      mask="(00) 0000-0000"
                                      type="text"
                                      name="phone"
                                      value={formData.phone}
                                      onChange={handleChange}
                                      id="floatingPhone"
                                      placeholder="Telefone"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingPhone">Telefone</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={12}>
                              <div className="form-floating mb-3">
                                  <IMaskInput
                                      mask="00000-000"
                                      type="text"
                                      name="cep"
                                      value={formData.cep}
                                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleCepChange(e.target.value)}
                                      id="floatingCep"
                                      placeholder="CEP"
                                      required
                                      className={`form-control ${!cepValid && 'is-invalid'}`}
                                  />
                                  <Form.Label htmlFor="floatingCep">CEP</Form.Label>
                                  {!cepValid && <div className="invalid-feedback">Informe um CEP válido</div>}
                                  {cepLoading && <div>Consultando CEP...</div>}
                              </div>
                          </Col>
                      </Row>

                      <Row>
                          <Col md={10}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="street"
                                      value={cepData.street}
                                      onChange={handleChange}
                                      id="floatingStreet"
                                      placeholder="Rua"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingStreet">Rua</Form.Label>
                              </div>
                          </Col>
                          <Col md={2}>
                              <div className="form-floating mb-3">
                                  <Form.Control
                                      type="text"
                                      name="number"
                                      value={cepData.number}
                                      onChange={handleChange}
                                      id="floatingNumber"
                                      placeholder="N°"
                                      required
                                  />
                                  <Form.Label htmlFor="floatingNumber">N°</Form.Label>
                              </div>
                          </Col>
                      </Row>

                      <Row>
                        <Col md={4}>
                            <div className="form-floating mb-3">
                                <Form.Control
                                    type="text"
                                    name="neighborhood"
                                    value={cepData.neighborhood}
                                    onChange={handleChange}
                                    id="floatingNeighborhood"
                                    placeholder="Bairro"
                                    required
                                />
                                <Form.Label htmlFor="floatingNeighborhood">Bairro</Form.Label>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="form-floating mb-3">
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={cepData.city}
                                    onChange={handleChange}
                                    id="floatingCity"
                                    placeholder="Cidade"
                                    required
                                />
                                <Form.Label htmlFor="floatingCity">Cidade</Form.Label>
                            </div>
                        </Col>
                    
                        <Col md={4}>
                            <div className="form-floating mb-3">
                                <Form.Control
                                    type="text"
                                    name="state"
                                    value={cepData.state}
                                    onChange={handleChange}
                                    id="floatingState"
                                    placeholder="Estado"
                                    required
                                />
                                <Form.Label htmlFor="floatingState">Estado</Form.Label>
                            </div>
                        </Col>
                      </Row>
                  </>
              )}

              <Row className="mt-4">
                  <Col className='text-center'>
                      <button type="submit" className="btn btn-primary">
                          Prosseguir
                      </button>
                  </Col>
              </Row>
              </>
            )}  
          </Form>
         ) : (
          <ValidateCode formData={formData} onBack={handleBack}/>
        )}
      </>
     
    );
};

export default FormPreRegistration;
