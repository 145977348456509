import React, { useState } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import Select from 'react-select';

interface FilterEmployeeProps {
  onFilterChange: (filters: {
    department: string;
    position: string;
    role: string;
    name: string;
  }) => void;
}

const departments = [
  { value: 'hr', label: 'Human Resources' },
  { value: 'it', label: 'IT' },
  { value: 'finance', label: 'Finance' },
];

const positions = [
  { value: 'manager', label: 'Manager' },
  { value: 'developer', label: 'Developer' },
  { value: 'analyst', label: 'Analyst' },
];

const roles = [
  { value: 'admin', label: 'Admin' },
  { value: 'user', label: 'User' },
  { value: 'guest', label: 'Guest' },
];

const FilterEmployee: React.FC<FilterEmployeeProps> = ({ onFilterChange }) => {
  const [filters, setFilters] = useState({
    department: '',
    position: '',
    role: '',
    name: '',
  });

  const handleFilterChange = (field: string, value: any) => {
    const newFilters = { ...filters, [field]: value };
    setFilters(newFilters);
    onFilterChange(newFilters);
  };

  return (
    <Row className="mb-3">
      <Col xs={12} md={3}>
        <Select
          placeholder="Departamento"
          options={departments}
          isClearable
          onChange={(selectedOption) =>
            handleFilterChange('department', selectedOption ? selectedOption.value : '')
          }
        />
      </Col>
      <Col xs={12} md={3}>
        <Select
          placeholder="Cargo"
          options={positions}
          isClearable
          onChange={(selectedOption) =>
            handleFilterChange('position', selectedOption ? selectedOption.value : '')
          }
        />
      </Col>
      <Col xs={12} md={3}>
        <Select
          placeholder="Função"
          options={roles}
          isClearable
          onChange={(selectedOption) =>
            handleFilterChange('role', selectedOption ? selectedOption.value : '')
          }
        />
      </Col>
      <Col xs={12} md={3}>
        <Form.Control
          type="text"
          placeholder="Nome do Funcionário"
          onChange={(e) => handleFilterChange('name', e.target.value)}
        />
      </Col>
    </Row>
  );
};

export default FilterEmployee;
