import React, { useState, useEffect } from 'react';
import styles from './ValidateCode.module.scss';
import Spinner from 'react-bootstrap/Spinner';
import FinalMessage from '../FinalMessage/index';

interface ValidateCodeProps {
  formData: {
    contractType: string;
    partnerName: string;
    corporateName: string;
    tradeName: string;
    cnpj: string;
    cpf: string;
    rg: string;
    email: string;
    phone: string;
    address: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    codManager: string;
  };
  onBack: () => void;
}

const ValidateCode: React.FC<ValidateCodeProps> = ({ formData, onBack }) => {
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFinalMessage, setShowFinalMessage] = useState(false);


  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  useEffect(() => {
    if (code.join('').length === 6) {
      validateCode();
    }
  }, [code]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newCode = [...code];
    newCode[index] = e.target.value;

    // Mover o foco automaticamente para o próximo input
    if (e.target.value.length === 1 && index < code.length - 1) {
      const nextInput = document.getElementById(`code-input-${index + 1}`) as HTMLInputElement;
      nextInput?.focus();
    }

    setCode(newCode);
  };

  const handleResend = async () => {
    setCode(['', '', '', '', '', '']); // Resetar os valores dos inputs
    setTimer(60);
    setCanResend(false);

    try {
      const response = await fetch('http://webservices.gfttech.com.br/api/v1/email-enviar-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email }),
      });

      if (response.ok) {
        console.log('Código reenviado com sucesso');
      } else {
        console.error('Erro ao reenviar o código');
      }
    } catch (error) {
      console.error('Erro ao reenviar o código:', error);
    }
  };

  const validateCode = async () => {
    try {
      const response = await fetch('http://webservices.gfttech.com.br/api/v1/email-verificar', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: formData.email, code: code.join('') }),
      });

      if (response.status === 200) {
        setIsValid(true);
        submitPreRegistration();
      } else {
        setIsValid(false);
        setError(true);
        setTimeout(() => setError(false), 1000);
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setIsValid(false);
    }
  };

  const submitPreRegistration = async () => {
    try {
      const response = await fetch('http://webservices.gfttech.com.br/api/v1/v4-precadastro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          full_name: formData.partnerName,
          corporation_reason: formData.corporateName,
          fantasy_name: formData.tradeName,
          cpf: formData.cpf,
          rg: formData.rg,
          cnpj: formData.cnpj,
          email: formData.email,
          cellphone: formData.phone,
          partnership_start_date: new Date().toISOString().split('T')[0],
          streetResidential: formData.street,
          numberResidential: formData.number,
          complementResidential: '',
          neighborhoodResidential: formData.neighborhood,
          cityResidential: formData.city,
          stateResidential: formData.state,
          zip_codeResidential: formData.cep,
          countryResidential: 'Brasil',
          streetComercial: formData.street,
          numberComercial: formData.number,
          complementComercial: '',
          neighborhoodComercial: formData.neighborhood,
          cityComercial: formData.city,
          stateComercial: formData.state,
          zip_codeComercial: formData.cep,
          countryComercial: 'Brasil',
          situation_id: 1,
          position_id: 2
        }),
      });

      if (response.status === 201) {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setShowFinalMessage(true);
        }, 2000);
      } else {
        console.error('Erro ao realizar o pré-cadastro');
      }
    } catch (error) {
      console.error('Erro ao realizar o pré-cadastro:', error);
    }
  };

  return (
    <div className={styles.validateCodeContainer}>
      {loading ? (
        <Spinner animation="border" />
      ) : showFinalMessage ? (
        <FinalMessage />
      ) : (
        <>
          <p className='lead mt-5'>
            <b>Por gentileza, confirme o código que enviamos para o Email:</b>
          </p>
          <div className={styles.emailActions}>
            <p className={styles.emailText}>
              <b>{formData.email}</b>
            </p>
          </div>
          <div className={styles.codeInputs}>
            {code.map((digit, index) => (
              <input
                key={index}
                id={`code-input-${index}`}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e, index)}
                className={`${styles.codeInput} ${error ? styles.shake : ''} ${isValid === false ? styles.isInvalid : ''} ${isValid === true ? styles.isValid : ''}`}
              />
            ))}
          </div>
          <div className={styles.actions}>
            {canResend ? (
              <button onClick={handleResend} className={styles.resendButton}>Reenviar código</button>
            ) : (
              <p>Aguarde {timer} segundos para reenviar o código</p>
            )}
          </div>
          <div>
            <button onClick={onBack} className={styles.backButton}>Trocar Email</button>
          </div>
        </>
      )}
    </div>
  );  
}

export default ValidateCode;