import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DataExportModal from './ReviewDataExportEmployee';
import { RiFileExcel2Line } from "react-icons/ri";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

const DataExportExcel: React.FC = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  dayjs.extend(customParseFormat);

  const handleExport = async (selectedColumns: string[]) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await fetch('http://webservices.gfttech.com.br/api/v1/funcionario/buscar-todos', {
        method: 'GET',
        headers: headers
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch employees: ${response.status} ${response.statusText}`);
      }

      const employees = await response.json();

      if (!employees || !Array.isArray(employees)) {
        throw new Error('Invalid employee data format');
      }

      const data = employees.map((employee: any) => {
        const filteredEmployee: any = {};
        selectedColumns.forEach(column => {
          switch (column) {

            // Dados Pessoais
            case "Nome":
              filteredEmployee.Nome = employee.user_name || "";
              break;
            case "CPF":
              filteredEmployee.CPF = employee.cpf || "";
              break;
            case "RG":
              filteredEmployee.RG = employee.rg || "";
              break;
            case "Dt_Nascimento":
              const formattedDateBirth = employee.date_of_birth ? dayjs(employee.date_of_birth).format("DD/MM/YYYY") : "";
              filteredEmployee.Dt_Nascimento = formattedDateBirth;
              break;
            case "Genero":
              filteredEmployee.Genero = employee.gender || "";
              break;
            case "Estado_Civil":
              filteredEmployee.Estado_Civil = employee.marital_status || "";
              break;

            // Contato
            case "Telefone":
              filteredEmployee.Telefone = employee.residential_phone || "";
              break;
            case "Celular":
              filteredEmployee.Celular = employee.cellphone || "";
              break;
            case "Email":
              filteredEmployee.Email = employee.email || "";
              break;

            // Endereco
            case "CEP":
              filteredEmployee.CEP = employee.zip_code || "";
              break;
            case "Rua":
              filteredEmployee.Rua = employee.street || "";
              break;
            case "Numero":
              filteredEmployee.Numero = employee.number || "";
              break;
            case "Complemento":
              filteredEmployee.Complemento = employee.complement || "";
              break;
            case "Bairro":
              filteredEmployee.Bairro = employee.neighborhood || "";
              break;
            case "Cidade":
              filteredEmployee.Cidade = employee.city || "";
              break;
            case "Estado":
              filteredEmployee.Estado = employee.state || "";
              break;

            // Profissional
            case "Filial":
              filteredEmployee.Filial = employee.trading_name || "";
              break;
            case "Departamento":
              filteredEmployee.Departamento = employee.acesso ? employee.acesso.map((acc: any) => acc.department_name).join(", ") : "";
              break;
            case "Cargo":
              filteredEmployee.Cargo = employee.acesso ? employee.acesso.map((acc: any) => acc.role_name).join(", ") : "";
              break;
            case "Funcao":
              filteredEmployee.Funcao = employee.position_name || "";
              break;
            case "Dt_Contratacao":
              const formattedDtContratacao = employee.date_of_hiring ? dayjs(employee.date_of_hiring).format("DD/MM/YYYY") : "";
              filteredEmployee.Dt_Contratacao = formattedDtContratacao;
              break;
            case "Dt_Desligamento":
              const formattedDtDesligamento = employee.date_of_termination ? dayjs(employee.date_of_termination).format("DD/MM/YYYY") : "";
              filteredEmployee.Dt_Desligamento = formattedDtDesligamento;
              break;
            case "Tipo_Contrato":
              filteredEmployee.Tipo_Contrato = employee.contract_type || "";
              break;
            case "Salario":
              filteredEmployee.Salario = employee.salary || "";
              break;
            
            //Documentos
            case "CTPS":
              filteredEmployee.CTPS = employee.work_card || "";
              break;
            case "PIS":
              filteredEmployee.PIS = employee.pis_number || "";
              break;

            //Educação
            case "Formacao":
              filteredEmployee.Formacao = employee.education || "";
              break;

            //Familia
            case "Nome_Pai":
              filteredEmployee.Nome_Pai = employee.father_name || "";
              break;
            case "Nome_Mae":
              filteredEmployee.Nome_Mae = employee.mother_name || "";
              break;

            //Financeiro
            case "Banco":
              filteredEmployee.Banco = employee.bank_id || "";
              break;
            case "Agencia":
              filteredEmployee.Agencia = employee.account_holder || "";
              break;
            case "N_Conta":
              filteredEmployee.N_Conta = employee.account_number || "";
              break;
            case "Tipo_Conta":
              filteredEmployee.Tipo_Conta = employee.account_owner_type || "";
              break;
            case "Chave_Pix":
              filteredEmployee.Chave_Pix = employee.pix_key || "";
              break;

            default:
              break;
          }
        });
        return filteredEmployee;
      });

      const jsonData = {
        fileName: "GFTECH_RELATORIO_FUNCIONARIOS",
        fileHeaders: selectedColumns,
        data: data,
      };

      const exportResponse = await fetch('http://webapi.gfttech.com.br:3333/portal/transform-json-into-xlsx', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(jsonData)
      });

      if (!exportResponse.ok) {
        const errorText = await exportResponse.text();
        throw new Error(`Failed to export data: ${exportResponse.status} ${exportResponse.statusText}`);
      }

      const blob = await exportResponse.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `${jsonData.fileName}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

    } catch (error) {
      console.error('Error:', error);
      alert('Erro ao exportar os dados. Por favor, tente novamente.');
    }
  };

  return (
    <>
      <Button className="bg-success" onClick={handleShowModal}>
        <RiFileExcel2Line size={23} />
      </Button>
      <DataExportModal 
        show={showModal} 
        handleClose={handleCloseModal} 
        handleExport={handleExport} 
      />
    </>
  );
};

export default DataExportExcel;
