import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

interface TypeDocumentConfirmExcludeProps {
  TypeDocumentId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const TypeDocumentConfirmExclude: React.FC<TypeDocumentConfirmExcludeProps> = ({ TypeDocumentId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se o Função foi excluído com sucesso

  if (!TypeDocumentId) return null; // se TypeDocumentId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão do Função
  const deleteTypeDocument = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const response = await fetch(`http://webservices.gfttech.com.br/api/v1/documentos/tipo-documento/${TypeDocumentId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        // Se a exclusão for bem-sucedida, define isDeleted como true
        setIsDeleted(true);
        // Chama a função onDelete para atualizar o estado do componente pai, se necessário
        onDelete();
      } else {
        throw new Error('Erro ao excluir o Tipo de Documento');
      }
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir o Tipo de Documento:', error);
    }
  };

  // se o Tipo de Documento foi excluído com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tipo de Documento excluído com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir este Tipo de Documento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteTypeDocument}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default TypeDocumentConfirmExclude;
