import { Container, Nav} from "react-bootstrap";
import NavBar from "../../components/NavBar";

import { useState } from "react";
import styles from "../../styles/contentTemplate.module.scss";
import DepartmentComponent from "../../components/Register/Department/DepartmentComponent";
import RoleComponent from "../../components/Register/Position/PositionComponent";
import FunctionComponent from "../../components/Register/Role/RoleComponent";
import PermissionComponent from "../../components/Register/Permission/PermissionComponent";
import BankComponent from "../../components/Register/Bank/BankComponent";
import UnitComponent from "../../components/Register/Unit/UnitComponent";
import TypeDocument from "../../components/Register/TypeDocuments/TypeDocumentsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faBuildingColumns, faBuildingUser, faFolderOpen, faHelmetSafety, faLocationDot, faUnlockKeyhole } from "@fortawesome/free-solid-svg-icons";

export default function Parameters(){
  const [activeTab, setActiveTab] = useState("link-1"); // Estado para controlar o tab ativo

  // função para manipular a mudança de tab
  const handleTabChange = (eventKey: any) => {
    setActiveTab(eventKey);
  };

  // função para renderizar o componente correspondente ao tab ativo
  const renderActiveComponent = () => {
    switch (activeTab) {
      case "link-1":
        return <BankComponent />;
      case "link-2":
        return <DepartmentComponent />;
      case "link-3":
        return <RoleComponent />;
      case "link-4":
        return <FunctionComponent />;
      case "link-5":
        return <PermissionComponent />;
      case "link-6":
        return <UnitComponent/>;
      case "link-7":
        return <TypeDocument/>;
      default:
        return null;
    }
  };

  return(
    <>
      <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
        <section className={`${styles.navbarSection}`}>
          <NavBar/>
        </section>
        
        <section className={`${styles.contentSection} mt-5 pt-5`}>
          {/* renderizando as tabs */}
          <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabChange}>
            <Nav.Item>
              <Nav.Link eventKey="link-1">
                <FontAwesomeIcon icon={faBuildingColumns}/> Banco
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-2">
                <FontAwesomeIcon icon={faBuildingUser}/> Departamento
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-3">
                <FontAwesomeIcon icon={faBriefcase}/> Cargo
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-4">
                <FontAwesomeIcon icon={faHelmetSafety}/> Função
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-5">
                <FontAwesomeIcon icon={faUnlockKeyhole}/> Permissões
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-6">
                <FontAwesomeIcon icon={faLocationDot}/> Unidades
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="link-7">
                <FontAwesomeIcon icon={faFolderOpen}/> Documentos
              </Nav.Link>
            </Nav.Item>
          </Nav>
          {/* renderizando o componente correspondente ao tab ativo */}
          {renderActiveComponent()}
        </section>
      </Container>
    </>
  );
}
 