import React from 'react';
import { FloatingLabel, Form, Modal } from 'react-bootstrap';
import styles from './ModalReject.module.scss';
import ButtonPrimary from '../../../ButtonPrimary';
import ButtonSecondary from '../../../ButtonSecondary';
import ButtonLight from '../../../ButtonLight';
import ButtonDanger from '../../../ButtonDanger';

interface ModalRejectProps {
  show: boolean;
  onHide: () => void;
  onReject: () => void;
}

const ModalReject: React.FC<ModalRejectProps> = ({ show, onHide, onReject }) => {
  return (
    <Modal show={show} onHide={onHide} className={styles.customModal}>
      <Modal.Header closeButton className={styles.backgroundModal}>
        <Modal.Title className={styles.modalTitle}>Confirmar Reprovação</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles.backgroundModal} ${styles.bodyModal}`}>
        <FloatingLabel controlId="Observacao" label="Observação">
          <Form.Control
            as="textarea"
            placeholder="Observação"
            style={{ height: '100px' }}
          />
        </FloatingLabel>
      </Modal.Body>
      <Modal.Footer className={styles.backgroundModal}>
        <ButtonLight content="Cancelar" onClick={onHide}/>
        <ButtonDanger content="Reprovar" onClick={onReject}/>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalReject;