import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface DepartmentConfirmExcludeProps {
  DepartmentId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const DepartmentConfirmExclude: React.FC<DepartmentConfirmExcludeProps> = ({ DepartmentId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se o Departamento foi excluído com sucesso

  if (!DepartmentId) return null; // se DepartmentId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão do Departamento
  const deleteDepartment = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        throw new Error('Token not found');
      }
      
      // Faz a requisição DELETE para o endpoint correspondente
      await axios.delete(`http://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/deletar/${DepartmentId}`
        , { headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          },
        }
      );

      // Se a exclusão for bem-sucedida, define isDeleted como true
      setIsDeleted(true);

      // Chama a função onDelete para atualizar o estado do componente pai, se necessário
      onDelete();
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir o Departamento:', error);
    }
  };

  // se o Departamento foi excluído com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Departamento excluído com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir este Departamento?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteDepartment}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default DepartmentConfirmExclude;
