import React, { useEffect, useState } from 'react';
import { Table, Button, Container } from 'react-bootstrap';
import { FaCheck, FaTimes, FaHistory } from 'react-icons/fa';
import axios from 'axios';

import styles from './RegistrationList.module.scss';
import Pagination from '../../../Pagination';
import SpinnerDefault from '../../../Spinner';
import ModalReject from '../ModalReject';
import ModalApprove from '../ModalApprove';
import ModalHistoric from '../../ModalHistoric';

interface DataItem {
  approval_id: number;
  regional_approval_status: string;
  register_approval_status: string;
  partner_id: number;
  id_regional_manager: number | null;
  entry_date: string;
  updated_at_entry: string;
  regional_approval: any;
  register_approval: any;
  nome_regional: any;
}

export default function RegistrationListRegional() {
  const [data, setData] = useState<DataItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [modalApproveShow, setModalApproveShow] = useState(false);
  const [modalRejectShow, setModalRejectShow] = useState(false);
  const [modalHistoricShow, setModalHistoricShow] = useState(false); // Novo estado para o modal de histórico
  const [selectedPartnerId, setSelectedPartnerId] = useState<number | null>(null);
  const [isApproval, setIsApproval] = useState<boolean>(true); // true for approve, false for reject
  const [historyData, setHistoryData] = useState<Array<{ date: string; event: string; details: string }>>([
    { date: '01/08/2024', event: 'Cadastro Criado', details: 'O cadastro foi criado com sucesso.' },
    { date: '02/08/2024', event: 'Documentos Enviados', details: 'Os documentos foram enviados para análise.' },
    { date: '03/08/2024', event: 'Aprovação Regional', details: 'O cadastro foi aprovado pela regional.' },
  ]); // Dados do histórico estáticos

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');

        if (!token) {
          console.error('Token não encontrado no localStorage');
          return;
        }

        const response = await axios.get('http://webservices.gfttech.com.br/api/v1/precadastro-status', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setData(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleApprove = (id: number) => {
    setSelectedPartnerId(id);
    setIsApproval(true);
    setModalApproveShow(true);
  };

  const handleConfirmApprove = () => {
    if (selectedPartnerId !== null) {
      console.log(`Aprovar ${selectedPartnerId}`);
      // Lógica de aprovação aqui
      setModalApproveShow(false);
    }
  };

  const handleReject = (id: number) => {
    setSelectedPartnerId(id);
    setIsApproval(false);
    setModalRejectShow(true);
  };

  const handleConfirmReject = () => {
    if (selectedPartnerId !== null) {
      console.log(`Rejeitar ${selectedPartnerId}`);
      // Lógica de rejeição aqui
      setModalRejectShow(false);
    }
  };

  const handleHistory = (id: number) => {
    setSelectedPartnerId(id);
    setModalHistoricShow(true);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Container>
      {loading ? (
        <div className={styles.spinnerContainer}>
          <center>
            <SpinnerDefault size='2rem'/>
          </center>
        </div>
      ) : (
        <>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>ID Parceiro</th>
                <th>Pre Cadastro</th>
                <th className={styles.actionColumn}>Aprovar</th>
                <th className={styles.actionColumn}>Reprovar</th>
                <th className={styles.actionColumn}>Histórico</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item) => (
                <tr key={item.partner_id}>
                  <td>{item.partner_id}</td>
                  <td>{new Date(item.entry_date).toLocaleDateString('pt-BR')}</td>
                  <td className={styles.actionCell}>
                    <center>
                      <Button
                        variant="success"
                        onClick={() => handleApprove(item.partner_id)}
                        className={`${styles.button} ${styles.approveButton}`}
                      >
                        <FaCheck />
                      </Button>
                    </center>
                  </td>
                  <td className={styles.actionCell}>
                    <center>
                      <Button
                        variant="danger"
                        onClick={() => handleReject(item.partner_id)}
                        className={`${styles.button} ${styles.rejectButton}`}
                      >
                        <FaTimes />
                      </Button>
                    </center>
                  </td>
                  <td className={styles.actionCell}>
                    <center>
                      <Button
                        variant="info"
                        onClick={() => handleHistory(item.partner_id)}
                        className={`${styles.button} ${styles.historyButton}`}
                      >
                        <FaHistory />
                      </Button>
                    </center>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={data.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      <ModalApprove
        show={modalApproveShow}
        onHide={() => setModalApproveShow(false)}
        onApprove={handleConfirmApprove}
      />
      <ModalReject
        show={modalRejectShow}
        onHide={() => setModalRejectShow(false)}
        onReject={handleConfirmReject}
      />
      <ModalHistoric
        show={modalHistoricShow}
        onHide={() => setModalHistoricShow(false)}
        
      />
    </Container>
  );
}