import React, { useState, useEffect } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

interface FormData {
  detalheContratual: string;
  selectedContractCondition: string;
  selectedCommissionPeriod: string;
  selectedContractType: string;
  salary: string;
  dateOfHiring: string;
  hasCnpj: boolean;
}

interface ContractualDataFormProps {
  onFormDataChange: (newFormData: FormData) => void;
  initialFormData: Partial<FormData>; // Optional prop to initialize form data
}

const ContractualDataForm: React.FC<ContractualDataFormProps> = ({ onFormDataChange, initialFormData = {} }) => {
  const [formData, setFormData] = useState<FormData>({
    detalheContratual: '',
    selectedContractCondition: '',
    selectedCommissionPeriod: '',
    selectedContractType: '',
    salary: '',
    dateOfHiring: '',
    hasCnpj: false,
    ...initialFormData, // Initialize form data if provided
  });

  useEffect(() => {
    // Call onFormDataChange when initialFormData changes
    onFormDataChange(formData);
  }, [initialFormData, onFormDataChange, formData]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = event.target;

    let newValue: string | boolean = value; // Assume newValue is string or boolean initially

    if (type === 'checkbox') {
      newValue = (event.target as HTMLInputElement).checked; // Cast event.target to HTMLInputElement for 'checked' property
    }

    setFormData(prevState => ({
      ...prevState,
      [name]: newValue,
    }));

    // Persist form data change immediately
    onFormDataChange({ ...formData, [name]: newValue });
  };

  return (
    <>
      <div className="contractualData mt-5">
        <Row className="mb-4">
          <Col sm="12" md="3">
            <Form.Control
              type="date"
              name="dateOfHiring"
              placeholder="Data Admissão*"
              value={formData.dateOfHiring}
              onChange={handleChange}
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Control
              type="text"
              name="salary"
              placeholder="Salário*"
              value={formData.salary}
              onChange={handleChange}
            />
          </Col>
          <Col sm="12" md="3">
            <Form.Check
              inline
              label="Recebe Bônus"
              name="hasCnpj"
              type="checkbox"
              id="checkbox-cnpj"
              checked={formData.hasCnpj}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md="6">
            <Form.Label>Tipo do Contrato*</Form.Label>
            <Form.Select
              aria-label=""
              name="selectedContractType"
              value={formData.selectedContractType}
              onChange={handleChange}
            >
              <option value="">Selecione</option>
              <option value="CLT">Celetista</option>
              <option value="PJ">Prestador de Serviços</option>
            </Form.Select>
          </Col>
          <Col sm="12" md="6">
            <Form.Label>Detalhes Contratuais</Form.Label>
            <Form.Select
              aria-label=""
              name="detalheContratual"
              value={formData.detalheContratual}
              onChange={handleChange}
            >
              <option value="">Selecione</option>
              <option value="acordo-remuneracao-pj">Acordo Remuneração PJ</option>
              <option value="acordo-comissao-inicial">Acordo Comissão Inicial</option>
              <option value="sem-detalhes">Sem detalhes</option>
            </Form.Select>
          </Col>
        </Row>

        {/* Campos para acordo remuneração PJ */}
        {formData.detalheContratual === 'acordo-remuneracao-pj' && (
          <Row className="mt-3">
            <Col sm="12" md="6">
              <Form.Label>Condição/Tempo</Form.Label>
              <Form.Select
                aria-label=""
                name="selectedContractCondition"
                value={formData.selectedContractCondition}
                onChange={handleChange}
              >
                <option value="">Selecione</option>
                <option value="1">Após 30 dias</option>
                <option value="2">Após 60 dias</option>
                <option value="3">Após 90 dias</option>
              </Form.Select>
            </Col>
            <Col sm="12" md="6">
              <Form.Label>Valor do Salário*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Informe o valor do salário"
                name="salary"
                value={formData.salary}
                onChange={handleChange}
              />
            </Col>
          </Row>
        )}

        {/* Campos para acordo comissão inicial */}
        {formData.detalheContratual === 'acordo-comissao-inicial' && (
          <Row className="mt-3">
            <Col sm="12" md="6">
              <Form.Label>Período de Vigência</Form.Label>
              <Form.Select
                aria-label=""
                name="selectedCommissionPeriod"
                value={formData.selectedCommissionPeriod}
                onChange={handleChange}
              >
                <option value="">Selecione</option>
                <option value="1">Até 30 dias</option>
                <option value="2">Até 60 dias</option>
                <option value="3">Até 90 dias</option>
              </Form.Select>
            </Col>
            <Col sm="12" md="6">
              <Form.Label>Valor da Comissão%</Form.Label>
              <Form.Control
                type="text"
                placeholder="Informe o valor da comissão"
                name="commissionPercentage"
                
                onChange={handleChange}
              />
            </Col>
          </Row>
        )}
      </div>
    </>
  );
};

export default ContractualDataForm;
