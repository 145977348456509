import React, { useState, useEffect } from 'react';
import { Offcanvas, ListGroup } from 'react-bootstrap';
import axios from 'axios';

interface ViewDetailsCnpjProps {
  show: boolean;
  onHide: () => void;
  cnpj: string | undefined;
}

const formatDate = (dateString: string) => {
  if (!dateString) return '';
  
const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

const ViewDetailsCnpj: React.FC<ViewDetailsCnpjProps> = ({ show, onHide, cnpj }) => {
  const [cnpjData, setCnpjData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      if (cnpj) {
        try {
          setLoading(true);
          const formattedCnpj = cnpj.replace(/[^\d]/g, ''); // remover pontos e barras do CNPJ
          const response = await axios.get(`https://brasilapi.com.br/api/cnpj/v1/${formattedCnpj}`);
          setCnpjData(response.data);
        } catch (error) {
          setError('Dados do CNPJ não encontrados');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [cnpj]);

  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Detalhes do CNPJ</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {loading ? (
          <p>Carregando...</p>
        ) : error ? (
          <p>{error}</p>
        ) : cnpjData ? (
          <div>
            <ListGroup>
              <ListGroup.Item><strong>Razão Social:</strong> {cnpjData.razao_social}</ListGroup.Item>
              <ListGroup.Item><strong>CNPJ:</strong> {cnpjData.cnpj}</ListGroup.Item>
              <ListGroup.Item><strong>Porte:</strong> {cnpjData.porte}</ListGroup.Item>
              <ListGroup.Item><strong>Natureza Jurídica:</strong> {cnpjData.natureza_juridica}</ListGroup.Item>
              <ListGroup.Item><strong>Situação Cadastral:</strong> {cnpjData.descricao_situacao_cadastral}</ListGroup.Item>
              <ListGroup.Item><strong>Data de Início de Atividade:</strong> {formatDate(cnpjData.data_inicio_atividade)}</ListGroup.Item>
              <ListGroup.Item><strong>CNAE Principal:</strong> {cnpjData.cnae_fiscal_descricao}</ListGroup.Item>
              <ListGroup.Item><strong>CEP:</strong> {cnpjData.cep}</ListGroup.Item>
              <ListGroup.Item><strong>Endereço:</strong> {cnpjData.logradouro}, {cnpjData.numero}</ListGroup.Item>
              <ListGroup.Item><strong>Bairro:</strong> {cnpjData.bairro}</ListGroup.Item>
              <ListGroup.Item><strong>Município:</strong> {cnpjData.municipio} - {cnpjData.uf}</ListGroup.Item>
              <ListGroup.Item><strong>Telefone:</strong> {cnpjData.ddd_telefone_1}</ListGroup.Item>
              {cnpjData.opcao_pelo_mei && <ListGroup.Item><strong>Opção pelo MEI:</strong> Sim</ListGroup.Item>}
              {cnpjData.opcao_pelo_simples && <ListGroup.Item><strong>Opção pelo Simples:</strong> Sim</ListGroup.Item>}
            </ListGroup>
          </div>
        ) : (
          <p>Dados do CNPJ não encontrados</p>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ViewDetailsCnpj;
