import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import RoleConfirmExclude from '../RoleComponent/RoleConfirmExclude';
import RoleEditModal from '../RoleComponent/RoleEdit';
import Pagination from '../../../Pagination';

interface Role {
  role_id: number;
  role_name: string;
}

interface RoleListProps {
  onReload: () => void;
}

const RoleList: React.FC<RoleListProps> = ({ onReload }) => {
  const [selectedRoleIdForEdit, setSelectedRoleIdForEdit] = useState<number | null>(null);
  const [selectedRoleId, setSelectedRoleId] = useState<number | null>(null);
  const [Roles, setRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar-todos', null, headers);

        if (response.status === 200) {
          setRoles(response.result);
        } else {
          setError('Erro ao buscar os Funçãos. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os Funçãos. Por favor, tente novamente.');
      }
      finally {
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const handleShowModal = (RoleId: number) => {
    setSelectedRoleIdForEdit(RoleId);
  };

  const handleCloseModal = () => {
    setSelectedRoleIdForEdit(null);
    setError('');
    updateRoleList();
  };

  const handleRoleDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedRoleId) {
        throw new Error('Token or Role ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `http://webservices.gfttech.com.br/api/v1/dados-bancario/Função/deletar/${selectedRoleId}`, null, headers);

      if (response.status === 200) {
        // remover o Função excluído do estado de Funçãos
        setRoles(Roles.filter(Role => Role.role_id !== selectedRoleId));
        handleCloseModal();
      } else {

        // verificando se a mensagem de erro é realmente um erro da API ou se é apenas uma condição inesperada
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir o Função. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o Função. Por favor, tente novamente.');
    }
  };

  const handleRoleUpdate = async () => {
    // Lógica para atualização do Função
  };

  const updateRoleList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar-todos', null, headers);

      if (response.status === 200) {
        setRoles(response.result);
      } else {
        setError('Erro ao buscar os Funçãos. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os Funçãos. Por favor, tente novamente.');
    }

  };

  const handlePageChange = (page: number) => {  
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentRoles = Array.isArray(Roles) ? Roles.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Função</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentRoles.map((Role) => (
                <tr key={Role.role_id}>
                  <td>{Role.role_name}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(Role.role_id)}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedRoleId(Role.role_id)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={Roles.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedRoleId && (
        <RoleConfirmExclude RoleId={selectedRoleId} onClose={() => { setSelectedRoleId(null); updateRoleList(); }} onDelete={handleRoleDelete} error={error} />
      )}
      
      {/* Adicionando o modal de edição */}
      {selectedRoleIdForEdit && (
        <RoleEditModal RoleId={selectedRoleIdForEdit} onClose={handleCloseModal} onError={setError} onUpdate={handleRoleUpdate} />
      )}
    </Col>
  );
};

export default RoleList;
