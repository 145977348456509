import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import styles from '../../AccessManagementEmployee.module.scss';

interface Address {
  address_id: number;
  street: string;
  number: string;
  complement: string | null;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string | null;
  country: string;
}

const EditAddress: React.FC<{ id: string }> = ({ id }) => {
  const [addressDetails, setAddressDetails] = useState<Address | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchAddressDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || !id) {
          throw new Error('Token or User ID not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/endereco/buscar-usuario/${id}`, null, headers);

        if (response.status === 200) {
          setAddressDetails(response.result.address);
        } else {
          setError('Erro ao buscar os detalhes do endereço. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os detalhes do endereço. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchAddressDetails();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setAddressDetails((prevState) => ({
      ...prevState!,
      [name]: value,
    }));

    if (name === 'zip_code' && value.length === 8) {
      fetchCepDetails(value);
    }
  };

  const fetchCepDetails = async (cep: string) => {
    try {
      const response = await fetch(`https://brasilapi.com.br/api/cep/v2/${cep}`);
      const data = await response.json();

      if (response.ok) {
        setAddressDetails((prevState) => ({
          ...prevState!,
          street: data.street,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
        }));
      } else {
        setError('Erro ao buscar os detalhes do CEP. Por favor, verifique o CEP e tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os detalhes do CEP. Por favor, verifique o CEP e tente novamente.');
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !id) {
        throw new Error('Token or User ID not found');
      }

      const data = {
        street: addressDetails?.street,
        number: addressDetails?.number,
        complement: addressDetails?.complement,
        neighborhood: addressDetails?.neighborhood,
        city: addressDetails?.city,
        state: addressDetails?.state,
        zip_code: addressDetails?.zip_code,
        country: addressDetails?.country,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/endereco/atualizar/${id}`, JSON.stringify(data), headers);

      if (response.status === 200) {
        alert('Endereço atualizado com sucesso');
        window.location.reload();
      } else {
        setError('Erro ao atualizar o endereço. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao atualizar o endereço. Por favor, tente novamente.');
    }
  };

  return (
    <Col className='mt-5' sm={12} md={12}>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className={`${styles.mainTitle} display-5`}>Endereço do Funcionário</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col>
                    <Form.Group controlId="formZipCode">
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        type="text"
                        name="zip_code"
                        value={addressDetails?.zip_code || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={8}>
                    <Form.Group controlId="formStreet">
                      <Form.Label>Rua</Form.Label>
                      <Form.Control
                        type="text"
                        name="street"
                        value={addressDetails?.street || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formNumber">
                      <Form.Label>Número</Form.Label>
                      <Form.Control
                        type="text"
                        name="number"
                        value={addressDetails?.number || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col>
                    <Form.Group controlId="formComplement">
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        name="complement"
                        value={addressDetails?.complement || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={5}>
                    <Form.Group controlId="formNeighborhood">
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type="text"
                        name="neighborhood"
                        value={addressDetails?.neighborhood || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId="formCity">
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={addressDetails?.city || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group controlId="formState">
                      <Form.Label>Estado</Form.Label>
                      <Form.Control
                        type="text"
                        name="state"
                        value={addressDetails?.state || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </Button>
          </div>
          {error && <p className="text-danger mt-3">{error}</p>}
        </>
      )}
    </Col>
  );
};

export default EditAddress;
