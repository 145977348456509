import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import TypeDocumentConfirmExclude from '../TypeDocumentsComponent/TypeDocumentsConfirmExclude';
import TypeDocumentEditModal from '../TypeDocumentsComponent/TypeDocumentsEdit';
import Pagination from '../../../Pagination';

interface TypeDocument {
  type_document_id: number;
  type_document: string;
}

interface TypeDocumentListProps {
  onReload: () => void;
}

const TypeDocumentList: React.FC<TypeDocumentListProps> = ({ onReload }) => {
  const [selectedTypeDocumentIdForEdit, setSelectedTypeDocumentIdForEdit] = useState<number | null>(null);
  const [selectedTypeDocumentId, setSelectedTypeDocumentId] = useState<number | null>(null);
  const [TypeDocuments, setTypeDocuments] = useState<TypeDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchTypeDocuments = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/documentos/buscar-tipos-documentos', null, headers);

        console.log('API Response:', response);  // Debug log to check API response

        if (response.status === 200 && response.result) {
          setTypeDocuments(response.result);
        } else {
          setError('Erro ao buscar os Tipos de documentos. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os Tipos de documentos. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchTypeDocuments();
  }, []);

  const handleShowModal = (typeDocumentId: number) => {
    setSelectedTypeDocumentIdForEdit(typeDocumentId);
  };

  const handleCloseModal = () => {
    setSelectedTypeDocumentIdForEdit(null);
    setError('');
    updateTypeDocumentList();
  };

  const handleTypeDocumentDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedTypeDocumentId) {
        throw new Error('Token or TypeDocument ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `http://webservices.gfttech.com.br/api/v1/documentos/tipo-documento/${selectedTypeDocumentId}`, null, headers);

      if (response.status === 200) {
        // Remover o Tipo de Documento excluído do estado
        setTypeDocuments(TypeDocuments.filter(typeDocument => typeDocument.type_document_id !== selectedTypeDocumentId));
        handleCloseModal();
      } else {
        // Verificando se a mensagem de erro é realmente um erro da API ou se é apenas uma condição inesperada
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir o Tipo de Documento. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o Tipo de Documento. Por favor, tente novamente.');
    }
  };

  const handleTypeDocumentUpdate = async () => {
    // Lógica para atualização do Tipo de Documento
  };

  const updateTypeDocumentList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/documentos/buscar-tipos-documentos', null, headers);

      if (response.status === 200 && response.result) {
        setTypeDocuments(response.result);
      } else {
        setError('Erro ao buscar os Tipos de Documentos. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os Tipos de Documentos. Por favor, tente novamente.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTypeDocuments = Array.isArray(TypeDocuments) ? TypeDocuments.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Tipo de Documento</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentTypeDocuments.map((typeDocument) => (
                <tr key={typeDocument.type_document_id}>
                  <td>{typeDocument.type_document}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(typeDocument.type_document_id)}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedTypeDocumentId(typeDocument.type_document_id)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={TypeDocuments.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedTypeDocumentId && (
        <TypeDocumentConfirmExclude TypeDocumentId={selectedTypeDocumentId} onClose={() => { setSelectedTypeDocumentId(null); updateTypeDocumentList(); }} onDelete={handleTypeDocumentDelete} error={error} />
      )}

      {/* Adicionando o modal de edição */}
      {selectedTypeDocumentIdForEdit && (
        <TypeDocumentEditModal TypeDocumentId={selectedTypeDocumentIdForEdit} onClose={handleCloseModal} onError={setError} onUpdate={handleTypeDocumentUpdate} />
      )}
    </Col>
  );
};

export default TypeDocumentList;
