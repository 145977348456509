import { useState } from 'react';
import { Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import Select, { SingleValue } from 'react-select';
import { FaSearch } from 'react-icons/fa';
import styles from './FilterRegistration.module.scss';
import ButtonPrimary from '../../../ButtonPrimary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface Option {
  value: string;
  label: string;
}

const managersOptions: Option[] = [
  { value: 'manager1', label: 'Gerente 1' },
  { value: 'manager2', label: 'Gerente 2' },

];

const statusOptions: Option[] = [
  { value: 'active', label: 'Ativo' },
  { value: 'inactive', label: 'Inativo' },

];

const filialOptions: Option[] = [
  { value: 'Filial A', label: 'Filial A' },
  { value: 'Filial B', label: 'Filial B' },

];


export default function FilterRegistrationRegional() {
  const [partnerName, setPartnerName] = useState('');
  const [selectedManager, setSelectedManager] = useState<SingleValue<Option>>(null);
  const [selectedStatus, setSelectedStatus] = useState<SingleValue<Option>>(null);
  const [selectedFilial, setSelectedFilial] = useState<SingleValue<Option>>(null);
  const handleSearch = () => {
    console.log('Nome do Parceiro:', partnerName);
    console.log('Gerente Selecionado:', selectedManager);
    console.log('Status Selecionado:', selectedStatus);
  };

  return (
    <div className={`${styles.sectionFilter} p-5`}>
      <Row className="align-items-center mb-3">
        <Col md={2} xs={12} className="mb-2 mb-md-0">
          <InputGroup>
            <FormControl
              placeholder="Parceiro"
              value={partnerName}
              onChange={(e) => setPartnerName(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col md={3} xs={12} className="mb-2 mb-md-0">
          <Select
            options={managersOptions}
            value={selectedManager}
            onChange={(option: SingleValue<Option>) => setSelectedManager(option)}
            placeholder="Gerente"
            isSearchable
            isClearable
            className={styles.select}
          />
        </Col>
        <Col md={3} xs={12} className="mb-2 mb-md-0">
          <Select
            options={statusOptions}
            value={selectedStatus}
            onChange={(option: SingleValue<Option>) => setSelectedStatus(option)}
            placeholder="Status"
            isSearchable
            isClearable
            className={styles.select}
          />
        </Col>
        <Col md={3} xs={12} className="mb-2 mb-md-0">
          <Select
            options={filialOptions}
            value={selectedFilial}
            onChange={(option: SingleValue<Option>) => setSelectedFilial(option)}
            placeholder="Filial"
            isSearchable
            isClearable
            className={styles.select}
          />
        </Col>
        <Col md={1} xs={12}>
          <ButtonPrimary content={<FontAwesomeIcon icon={faSearch}/>} onClick={handleSearch}/>
        </Col>
      </Row>
    </div>
  );
}