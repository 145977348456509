import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

interface RoleConfirmExcludeProps {
  RoleId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const RoleConfirmExclude: React.FC<RoleConfirmExcludeProps> = ({ RoleId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se o Função foi excluído com sucesso

  if (!RoleId) return null; // se RoleId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão do Função
  const deleteRole = async () => {
    try {
      // Faz a requisição DELETE para o endpoint correspondente
      await axios.delete(`http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/deletar/${RoleId}`);

      // Se a exclusão for bem-sucedida, define isDeleted como true
      setIsDeleted(true);

      // Chama a função onDelete para atualizar o estado do componente pai, se necessário
      onDelete();
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir a Função:', error);
    }
  };

  // se o Função foi excluído com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Função excluída com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir esta Função?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteRole}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default RoleConfirmExclude;
