import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import BankConfirmExclude from '../BankComponent/BankConfirmExclude';
import BankEditModal from '../BankComponent/BankEdit';
import Pagination from '../../../Pagination';

interface Bank {
  id_bank: number;
  name: string;
  number_bank: number;
}

interface BankListProps {
  onReload: () => void;
}

const BankList: React.FC<BankListProps> = ({ onReload }) => {
  const [selectedBankIdForEdit, setSelectedBankIdForEdit] = useState<number | null>(null);
  const [selectedBankId, setSelectedBankId] = useState<number | null>(null);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/buscar-todos', null, headers);

        if (response.status === 200) {
          setBanks(response.result);
        } else {
          setError('Erro ao buscar os bancos. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os bancos. Por favor, tente novamente.');
      }
      finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, []);

  const handleShowModal = (bankId: number) => {
    setSelectedBankIdForEdit(bankId);
  };

  const handleCloseModal = () => {
    setSelectedBankIdForEdit(null);
    setError('');
    updateBankList();
  };

  const handleBankDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedBankId) {
        throw new Error('Token or bank ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/deletar/${selectedBankId}`, null, headers);

      if (response.status === 200) {
        setBanks(banks.filter(bank => bank.id_bank !== selectedBankId));
        handleCloseModal();
      } else {
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir o banco. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir o banco. Por favor, tente novamente.');
    }
  };

  const handleBankUpdate = async () => {
    updateBankList();
  };

  const updateBankList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/buscar-todos', null, headers);

      if (response.status === 200) {
        setBanks(response.result);
      } else {
        setError('Erro ao buscar os bancos. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar os bancos. Por favor, tente novamente.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentBanks = Array.isArray(banks) ? banks.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Nº Banco</th>
                <th>Nome do Banco</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentBanks.map((bank) => (
                <tr key={bank.number_bank}>
                  <td style={{ width: '15%' }}>{bank.number_bank}</td>
                  <td>{bank.name}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(bank.id_bank)}>
                      <FontAwesomeIcon icon={faEdit}/>
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedBankId(bank.id_bank)}>
                      <FontAwesomeIcon icon={faTrash}/>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={banks.length}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedBankId && (
        <BankConfirmExclude bankId={selectedBankId} onClose={() => { setSelectedBankId(null); updateBankList(); }} onDelete={handleBankDelete} error={error} />
      )}
      
      {selectedBankIdForEdit && (
        <BankEditModal bankId={selectedBankIdForEdit} onClose={handleCloseModal} onError={setError} onUpdate={handleBankUpdate} />
      )}
    </Col>
  );
};

export default BankList;
