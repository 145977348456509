import { Button, Row } from 'react-bootstrap';
import styles from '../../Register.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useState, useRef } from 'react';
import PermissionModal from './PermissionModal';
import PermissionList from '../PermissionList';

export default function PermissionComponent(){

  const [showModal, setShowModal] = useState(false);
  const [reloadPermissionList, setReloadPermissionList] = useState(false); 
  const keyRef = useRef(0); // Referência para a chave única do PermissionList

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReloadPermissionList(true); 
  };

  const handlePermissionListReloaded = () => {
    setReloadPermissionList(false); 
  };

  const toggleKey = () => {
    keyRef.current = keyRef.current === 0 ? 1 : 0; // Alternando entre 0 e 1
  };

  return(
    <div className="mt-5">
      <Row>
        <h1 className={`${styles.titleContent} display-6`}>Controle de Permissões</h1>
      </Row>

      <Button className={`${styles.buttonAdd} mt-5`} onClick={handleShowModal}>
        <FontAwesomeIcon icon={faAdd} />
      </Button>

      <div className="container">
        {/* Adicionando a chave única dinâmica e passando a função de recarregamento como prop */}
        <PermissionList key={`${reloadPermissionList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handlePermissionListReloaded} />
      </div>

      {showModal && <PermissionModal onClose={() => {handleCloseModal(); toggleKey();}} />}
    </div>
  );
}
