import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditDepartmentModalProps {
  DepartmentId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const DepartmentEditModal: React.FC<EditDepartmentModalProps> = ({ DepartmentId, onClose, onUpdate, onError }) => {
  const [DepartmentName, setDepartmentName] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (DepartmentId) {
      fetchDepartmentName();
    }
  }, [DepartmentId]);

  const fetchDepartmentName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !DepartmentId) {
        throw new Error('Token or Department ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/buscar-informacoes/${DepartmentId}`, null, headers);

      if (response.status === 200) {
        setDepartmentName(response.result.department[0].department_name);
      } else {
        throw new Error('Erro ao buscar o nome do departamento. Por favor, tente novamente.');
      }
    } catch (error: any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome do departamento. Por favor, tente novamente.');
    }
  };

  const handleDepartmentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDepartmentName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !DepartmentId) {
        throw new Error('Token or Department ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/departamento-papel/departamento/atualizar/${DepartmentId}`, { department_name: DepartmentName }, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true); // Definir sucesso como true para exibir a mensagem
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar o departamento. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar o departamento. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar o departamento. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Departamento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Departamento atualizado com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formDepartmentName" className='mb-5'>
            <Form.Control type="text" placeholder="Digite o nome do departamento" value={DepartmentName} onChange={handleDepartmentNameChange} required />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default DepartmentEditModal;
