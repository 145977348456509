import { Container } from "react-bootstrap";
import NavBar from "../../components/NavBar";
import AccessAnalysis from "./AccessAnalysis";
import styles from "../../styles/contentTemplate.module.scss"

export default function AdminGeneral() {
  return (
    <Container fluid className="p-0" style={{overflowX: 'hidden'}}>
      <section className={styles.navbarSection}>
        <NavBar/>
      </section>
      <section className={styles.contentSection}>
       {/*<AccessAnalysis/>*/}
      </section>
    </Container>
  );
}
