import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faKey, faDollarSign, faMapMarkerAlt, faFileContract, faHistory } from '@fortawesome/free-solid-svg-icons';
import EditPersonalData from '../../../../pages/AccessManagementEmployee/EditEmployee/EditPersonalData';
import EditParamsUser from '../../../../pages/AccessManagementEmployee/EditEmployee/EditParamsUser';
import EditAddress from '../../../../pages/AccessManagementEmployee/EditEmployee/EditAdress';
import EditFinancial from '../../../../pages/AccessManagementEmployee/EditEmployee/EditFinancial';
import EditContractualData from '../../../../pages/AccessManagementEmployee/EditEmployee/EditContractualData';

interface MenuEditEmployeeProps {
  id: string;
}

const MenuEditEmployee: React.FC<MenuEditEmployeeProps> = ({ id }) => {
  const [activeKey, setActiveKey] = useState('home');

  const handleSelect = (eventKey: string | null) => {
    if (eventKey) {
      setActiveKey(eventKey);
    }
  };

  return (
    <>
      <Nav variant="tabs" activeKey={activeKey} onSelect={handleSelect}>
        <Nav.Item>
          <Nav.Link eventKey="home">
            <FontAwesomeIcon icon={faUser} /> Dados Pessoais
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-3">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Endereço
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-1">
            <FontAwesomeIcon icon={faKey} /> Parâmetros de Acesso
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-2">
            <FontAwesomeIcon icon={faDollarSign} /> Financeiro
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-4">
            <FontAwesomeIcon icon={faFileContract} /> Dados Contratuais
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="link-5">
            <FontAwesomeIcon icon={faHistory} /> Histórico
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <div className="tab-content mt-3">
        {activeKey === 'home' && <EditPersonalData id={id} />}
        {activeKey === 'link-1' && <EditParamsUser id={id}/>}
        {activeKey === 'link-2' && <EditFinancial id={id}/>}
        {activeKey === 'link-3' && <EditAddress id={id} />}
        {activeKey === 'link-4' && <EditContractualData id={id}/>}
        {activeKey === 'link-5' && <p>Histórico</p>}
      </div>
    </>
  );
}

export default MenuEditEmployee;
