import React from 'react';
import { Col, Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import NavBar from '../../../components/NavBar';
import styles from '../AccessManagementEmployee.module.scss';
import MenuEditEmployee from '../../../components/Register/EditEmployee/MenuEditEmployee';

interface EmployeeEditProps {}

const EmployeeEdit: React.FC<EmployeeEditProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
      <section className={styles.navbarSection}>
        <NavBar/>
      </section>
      <section className={`${styles.contentSection} container pt-5`}>
        <Col className='mt-5' sm={12} md={10}>
          <Button variant="secondary" onClick={handleBack} className="mb-3">
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <div className='mt-2 mb-5'>
            {id ? <MenuEditEmployee id={id} /> : <p>Id do funcionário não encontrado.</p>}
          </div>
        </Col>
      </section>
    </Container>
  );
};

export default EmployeeEdit;
