import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditPositionModalProps {
  PositionId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const PositionEditModal: React.FC<EditPositionModalProps> = ({ PositionId, onClose, onUpdate, onError }) => {
  const [PositionName, setPositionName] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (PositionId) {
      fetchPositionName();
    }
  }, [PositionId]);

  const fetchPositionName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !PositionId) {
        throw new Error('Token or Position ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/posicao/buscar-usuario/${PositionId}`, null, headers);
      console.log(PositionId);

      if (response.status === 200 && response.result) {
        setPositionName(response.result.Position.position_name);
      } else {
        throw new Error('Erro ao buscar o nome do Cargo. Por favor, tente novamente.');
      }
    } catch (error: any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome do Cargo. Por favor, tente novamente.');
    }
  };

  const handlePositionNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPositionName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !PositionId) {
        throw new Error('Token or Position ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/posicao/atualizar/${PositionId}`, { position_name: PositionName }, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true); // Definir sucesso como true para exibir a mensagem
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar o Cargo. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar o Cargo. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar o Cargo. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Cargo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Cargo atualizado com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPositionName" className='mb-5'>
            <Form.Control type="text" placeholder="Digite o nome do Cargo" value={PositionName} onChange={handlePositionNameChange} required />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default PositionEditModal;
