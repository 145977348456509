import React, { useState } from 'react';
import { Col, Row, Button, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import styles from '../../AccessManagementEmployee.module.scss';

interface FinancialDetails {
  agency: string;
  accountNumber: string;
  accountDigit: string;
  bank: string;
  accountType: string;
  pixKey: string;
  rate: string;
}

const EditFinancial: React.FC<{ id: string }> = ({ id }) => {
  const [financialDetails, setFinancialDetails] = useState<FinancialDetails>({
    agency: '',
    accountNumber: '',
    accountDigit: '',
    bank: '',
    accountType: '',
    pixKey: '',
    rate: '',
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFinancialDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Save function to be implemented
    console.log(financialDetails);
  };

  return (
    <Col className='mt-5' sm={12} md={12}>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className={`${styles.mainTitle} display-5`}>Dados Financeiros do Funcionário</h5>
          </div>
        </Card.Header>
        <Card.Body>
          <Form>
            <Row>
              <Col md={4}>
                <Form.Group controlId="formAgency">
                  <Form.Label>Agência</Form.Label>
                  <Form.Control
                    type="text"
                    name="agency"
                    value={financialDetails.agency}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAccountNumber">
                  <Form.Label>Número da Conta</Form.Label>
                  <Form.Control
                    type="text"
                    name="accountNumber"
                    value={financialDetails.accountNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="formAccountDigit">
                  <Form.Label>Dígito da Conta</Form.Label>
                  <Form.Control
                    type="text"
                    name="accountDigit"
                    value={financialDetails.accountDigit}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6}>
                <Form.Group controlId="formBank">
                  <Form.Label>Banco</Form.Label>
                  <Form.Control
                    type="text"
                    name="bank"
                    value={financialDetails.bank}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formAccountType">
                  <Form.Label>Tipo da Conta</Form.Label>
                  <Form.Control
                    type="text"
                    name="accountType"
                    value={financialDetails.accountType}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-3'>
              <Col md={6}>
                <Form.Group controlId="formPixKey">
                  <Form.Label>Chave Pix</Form.Label>
                  <Form.Control
                    type="text"
                    name="pixKey"
                    value={financialDetails.pixKey}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formRate">
                  <Form.Label>Taxa</Form.Label>
                  <Form.Control
                    type="text"
                    name="rate"
                    value={financialDetails.rate}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <div className="d-flex justify-content-center mt-4">
        <Button variant="primary" onClick={handleSave}>
          <FontAwesomeIcon icon={faSave} /> Salvar
        </Button>
      </div>
    </Col>
  );
};

export default EditFinancial;
