import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditTypeDocumentModalProps {
  TypeDocumentId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const TypeDocumentEditModal: React.FC<EditTypeDocumentModalProps> = ({ TypeDocumentId, onClose, onUpdate, onError }) => {
  const [TypeDocumentName, setTypeDocumentName] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (TypeDocumentId) {
      fetchTypeDocumentName();
    }
  }, [TypeDocumentId]);

  const fetchTypeDocumentName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !TypeDocumentId) {
        throw new Error('Token or TypeDocument ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/permissao-papel/papel/buscar/${TypeDocumentId}`, null, headers);

      if (response.status === 201) {
        setTypeDocumentName(response.result.type_document);
      } else {
        throw new Error('Erro ao buscar o nome do Tipo de Documento. Por favor, tente novamente.');
      }
    } catch (error: any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome do Tipo de Documento. Por favor, tente novamente.');
    }
  };

  const handleTypeDocumentNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTypeDocumentName(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !TypeDocumentId) {
        throw new Error('Token or TypeDocument ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/documentos/tipo-documento/${TypeDocumentId}`, 
        { 
          types_of_document: TypeDocumentName
        }, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true);
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar o Tipo de Documento. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar o Tipo de Documento. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar o Tipo de Documento. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Tipo de Documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Tipo de Documento atualizado com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formTypeDocumentName">
              <Form.Control type="text" placeholder="Nome do Tipo de Documento" value={TypeDocumentName} onChange={handleTypeDocumentNameChange} required />
            </Form.Group>
          </Row>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default TypeDocumentEditModal;