import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Container, Spinner, Card, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircleInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import Bonus from './Bonus';
import styles from './Benefits.module.scss';
import SpecialConditions from './SpecialConditions';

interface CR {
  crm: number;
  in100: number;
}

interface BenefitsProps {
  codPartner: string;
}

const msgDataAtivacaoCR = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Ativado em: dd/mm/aaaa
  </Tooltip>
);

const msgDataAtivacaoCM = (props: any) => (
  <Tooltip id="button-tooltip2" {...props}>
    Ativado em: dd/mm/aaaa
  </Tooltip>
);

const Benefits: React.FC<BenefitsProps> = ({ codPartner }) => {
  const [benefitsData, setBenefitsData] = useState<{ bonus: number, condEspecial: number } | null>(null);
  const [crData, setCrData] = useState<CR | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [showBonusModal, setShowBonusModal] = useState<boolean>(false);
  const [showConditionsModal, setShowConditionsModal] = useState<boolean>(false);
  const numericCodPartner = Number(codPartner);


  const handleBonusModalClose = () => setShowBonusModal(false);
  const handleBonusModalShow = () => setShowBonusModal(true);

  const handleConditionsModalClose = () => setShowConditionsModal(false);
  const handleConditionsModalShow = () => setShowConditionsModal(true);

  useEffect(() => {
    async function fetchBenefitsData() {
      try {
        const response = await fetch(`http://bi.gfttech.com.br/api/v1/atendimento/beneficiosAtivos?codProdutorVenda=${codPartner}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const benefitData = data[0];

        setBenefitsData({
          bonus: benefitData.BONUS,
          condEspecial: benefitData.COND_ESPECIAL,
        });

        setCrData({
          crm: benefitData.CRM,
          in100: benefitData.IN100,
        });

        setIsLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados dos benefícios:', error);
        setIsError(true);
        setIsLoading(false);
      }
    }

    fetchBenefitsData();
  }, [codPartner]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </div>
    );
  }

  if (isError) {
    return <div>Erro ao carregar os dados.</div>;
  }

  return (
    <Container fluid>
      <h2 className={`display-6 mt-5 mb-5 ${styles.underlineTitle}`}>Benefícios</h2>
      <Row>
        <Col md={6} className='mt-3'>
          <Card>
            <Card.Header>
              <h4>Benefícios de Produção</h4>
            </Card.Header>
            <Card.Body className='pt-4' style={{ height: '200px' }}>
              <Table striped bordered hover className={styles.table}>
                <thead>
                  <tr>
                    <th>Benefício</th>
                    <th>Quantidade</th>
                    <th className="text-center" style={{ width: '15%' }}>Informações</th>
                  </tr>
                </thead>
                <tbody>
                  {benefitsData && (
                    <>
                      <tr>
                        <td>Bônus</td>
                        <td>{benefitsData.bonus}</td>
                        <td className="text-center">
                          <Button onClick={handleBonusModalShow} className={`btn ${styles.btnInfo}`}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Cond. Especial</td>
                        <td>{benefitsData.condEspecial}</td>
                        <td className="text-center">
                          <Button onClick={handleConditionsModalShow} className={`btn ${styles.btnInfo}`}>
                            <FontAwesomeIcon icon={faCircleInfo} />
                          </Button>
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className='mt-3'>
          <Card>
            <Card.Header>
              <h4>Sistemas</h4>
            </Card.Header>
            <Card.Body className='pt-4' style={{ height: '200px' }}>
              <Table striped bordered hover className={styles.table}>
                <thead>
                  <tr>
                    <th className="text-center">Consulta CR</th>
                    <th className="text-center">Consulta GC</th>
                  </tr>
                </thead>
                <tbody>
                  {crData && (
                    <tr>
                      <td className="text-center">
                        {crData.crm ? (
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={msgDataAtivacaoCR}
                          >
                            <span className={`${styles.iconSpan} text-success`}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span className={`${styles.iconSpan} text-secondary`}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {crData.in100 ? ( 
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={msgDataAtivacaoCM}
                          >
                            <span className={`${styles.iconSpan} text-success`}>
                              <FontAwesomeIcon icon={faCheck} />
                            </span>
                          </OverlayTrigger>
                        ) : (
                          <span className={`${styles.iconSpan} text-secondary`}>
                            <FontAwesomeIcon icon={faTimes} />
                          </span>
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Bonus Modal */}
      <Bonus
        codPartner={codPartner}
        show={showBonusModal}
        handleClose={handleBonusModalClose}
      />

      {/* Special Conditions Modal */}
      <SpecialConditions
        codProdutorVenda={numericCodPartner} 
        show={showConditionsModal}
        handleClose={handleConditionsModalClose}
      />

    </Container>
  );
};

export default Benefits;