import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Select from 'react-select';
import styles from './FilterPartnerPanel.module.scss';

interface Partner {
  cod_protudor_venda: number;
  nom_produtor_venda: string;
}

interface Option {
  value: number;
  label: string;
}

export default function FilterPartnerPanel() {
  const navigate = useNavigate();
  const [searchId, setSearchId] = useState('');
  const [partnerOptions, setPartnerOptions] = useState<Option[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<Option | null>(null);

  useEffect(() => {
    fetch('http://bi.gfttech.com.br/api/v1/atendimento/produtorId')
      .then(response => response.json())
      .then((data: Partner[]) => {
        const options = data.map((partner: Partner) => ({
          value: partner.cod_protudor_venda,
          label: partner.nom_produtor_venda
        }));
        setPartnerOptions(options);
      })
      .catch(error => console.error('Erro ao buscar parceiros:', error));
  }, []);

  const handleSearch = () => {
    if (searchId.trim()) {
      navigate(`/PainelParceiro/${searchId}`);
    }
  };

  const handlePartnerChange = (selectedOption: Option | null) => {
    setSelectedPartner(selectedOption);
    setSearchId(selectedOption ? selectedOption.value.toString() : '');
  };

  return (
    <div    className={`${styles.sectionFilter}`}>
      <Row className="align-items-end pb-5">
        <Col md={2} sm={12} className='mb-5'>
            <Form.Control
              type="number"
              placeholder="ID Parceiro"
              value={searchId}
              onChange={(e) => setSearchId(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
        
        </Col>
        <Col md={9} sm={12} className='mb-5'>
          <Select
            options={partnerOptions}
            value={selectedPartner}
            onChange={handlePartnerChange}
            placeholder="Nome Parceiro"
            className={`${styles.partnerSelect} ${styles.partnerSelectExpanded}`}
            classNamePrefix="react-select"
            menuPortalTarget={document.body}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Col>
        <Col md={1} className='mb-5'>
          <Button className={`${styles.searchButton} w-100`} onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </Col>
      </Row>
    </div>
  );
}