import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import AdminGeneral from './pages/Admin_General';
import AccessManagementPartner from './pages/AccessManagementPartner';
import AccessManagementEmployee from './pages/AccessManagementEmployee';
import NotFound from './pages/NotFound';
import Parameters from './pages/Parameters';
import HomeGecom from './pages/Comercial/Gecom/HomeGecom';
import EmployeeEdit from './pages/AccessManagementEmployee/EditEmployee';
import PreRegistration from './pages/PreRegistration';
import PartnerPanel from './pages/PartnerPanel';
import { jwtDecode } from "jwt-decode";
import RegistrationFlow from './pages/Regional/RegistrationFlow';
import RegistrationFlowRegional from './pages/Regional/RegistrationFlow';

// Função para verificar se o usuário está autenticado e o token é válido
async function isAuthenticated() {
  const token = localStorage.getItem('token');

  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp;
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);

    if (expirationTime <= currentTimeInSeconds) {
      console.log("expirou");
      localStorage.removeItem('token');
      alert('Acesso expirado! Favor logar novamente.');
      window.location.href = '/login';
      return false;
    }

    return true;
  } catch (error) {
    console.error('Erro ao decodificar o token:', error);
    return false;
  }
}

// Componente para rotas privadas
const PrivateRoute = ({ element: Component, ...rest }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      const authenticated = await isAuthenticated();
      setIsAuth(authenticated);
      setLoading(false);
    }
    checkAuth();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return isAuth ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/PreCadastro/:codManager?" element={<PreRegistration />} />
        <Route path="/adminGeneral" element={<PrivateRoute element={AdminGeneral} />} />
        <Route path="/AccessManagementPartner" element={<PrivateRoute element={AccessManagementPartner} />} />
        <Route path="/AccessManagementEmployee" element={<PrivateRoute element={AccessManagementEmployee} />} />
        <Route path="/AccessManagementEmployee/Edit/:id" element={<PrivateRoute element={EmployeeEdit} />} />
        <Route path="/Parameters" element={<PrivateRoute element={Parameters} />} />
        <Route path="/GerenteComercial" element={<PrivateRoute element={HomeGecom} />} />
        <Route path="/PainelCadastrosRegional" element={<PrivateRoute element={RegistrationFlowRegional}/> }/>
        <Route path="/PainelParceiro/:codPartner?" element={<PrivateRoute element={PartnerPanel} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
