import React, { useState, useEffect } from 'react';
import { Col, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import UnitConfirmExclude from '../UnitComponent/UnitConfirmExclude';
import UnitEditModal from '../UnitComponent/UnitEdit';
import Pagination from '../../../Pagination';

interface Unit {
  company_unit_id: number;
  name: string;
  trading_name: string;
  type: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  phone: string;
  email: string;
}

interface UnitListProps {
  onReload: () => void;
}

const UnitList: React.FC<UnitListProps> = ({ onReload }) => {
  const [selectedUnitIdForEdit, setSelectedUnitIdForEdit] = useState<number | null>(null);
  const [selectedUnitId, setSelectedUnitId] = useState<number | null>(null);
  const [units, setUnits] = useState<Unit[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchUnits = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/empresa/buscar-todas', null, headers);

        if (response.status === 200) {
          const unitsData = response.result || [];
          setUnits(unitsData);
          console.log(unitsData);
        } else {
          setError('Erro ao buscar as Unidades. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar as Unidades. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchUnits();
  }, []);

  const handleShowModal = (unitId: number) => {
    setSelectedUnitIdForEdit(unitId);
  };

  const handleCloseModal = () => {
    setSelectedUnitIdForEdit(null);
    setError('');
    updateUnitList();
  };

  const handleUnitDelete = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !selectedUnitId) {
        throw new Error('Token or Unit ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('delete', `http://webservices.gfttech.com.br/api/v1/empresa/deletar/${selectedUnitId}`, null, headers);

      if (response.status === 200) {
        setUnits(units.filter(unit => unit.company_unit_id !== selectedUnitId));
        handleCloseModal();
      } else {
        if (response.result && response.result.message) {
          setError(response.result.message);
        } else {
          setError('Erro ao excluir a unidade. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao excluir a unidade. Por favor, tente novamente.');
    }
  };

  const handleUnitUpdate = async () => {
    // Lógica para atualização do Cargo
  };

  const updateUnitList = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', 'http://webservices.gfttech.com.br/api/v1/empresa/buscar-todas', null, headers);

      if (response.status === 200) {
        const unitsData = response.result || [];
        setUnits(unitsData);
      } else {
        setError('Erro ao buscar as unidades. Por favor, tente novamente.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao buscar as Unidades. Por favor, tente novamente.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUnits = Array.isArray(units) ? units.slice(indexOfFirstItem, indexOfLastItem) : [];

  return (
    <Col sm={12} md={10}>
      {loading ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <Table striped bordered hover className="mt-5">
            <thead>
              <tr>
                <th>Unidade</th>
                <th>Nome Fantasia</th>
                <th>Tipo</th>
                <th>Editar</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {currentUnits.map((unit) => (
                <tr key={unit.company_unit_id}>
                  <td>{unit.name}</td>
                  <td>{unit.trading_name}</td>
                  <td>{unit.type}</td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-primary" onClick={() => handleShowModal(unit.company_unit_id)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                  </td>
                  <td style={{ width: '7%' }}>
                    <button className="btn btn-danger" onClick={() => setSelectedUnitId(unit.company_unit_id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            totalItems={units.length || 0}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {selectedUnitId && (
        <UnitConfirmExclude
          UnitId={selectedUnitId}
          onClose={() => { setSelectedUnitId(null); updateUnitList(); }}
          onDelete={handleUnitDelete}
          error={error}
        />
      )}
      {selectedUnitIdForEdit && (
        <UnitEditModal
          UnitId={selectedUnitIdForEdit}
          onClose={handleCloseModal}
          onError={setError}
          onUpdate={handleUnitUpdate}
        />
      )}
    </Col>
  );
};

export default UnitList;
