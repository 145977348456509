import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface BankConfirmExcludeProps {
  bankId: number | null;
  onClose: () => void;
  onDelete: () => void;
  error: string;
}

const BankConfirmExclude: React.FC<BankConfirmExcludeProps> = ({ bankId, onClose, onDelete, error }) => {
  const [isDeleted, setIsDeleted] = useState(false); // state para controlar se o banco foi excluído com sucesso

  if (!bankId) return null; // se bankId for nulo, não preciso renderizar o modal

  // Função para enviar a solicitação de exclusão do banco
  const deleteBank = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !bankId) {
        throw new Error('Token or bank ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Faz a requisição DELETE para o endpoint correspondente
      const response = await requestClient('DELETE', `http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/deletar/${bankId}`, null, headers);

      if (response.status === 204) {
        // Se a exclusão for bem-sucedida, define isDeleted como true
        setIsDeleted(true);

        // Chama a função onDelete para atualizar o estado do componente pai, se necessário
        onDelete();
      } else {
        throw new Error('Erro ao excluir o banco. Por favor, tente novamente.');
      }
    } catch (error) {
      // Se ocorrer algum erro, você pode lidar com ele aqui
      console.error('Erro ao excluir o banco:', error);
      console.log(bankId);
    }
  };

  // Se o banco foi excluído com sucesso, exibir mensagem e ocultar botões
  if (isDeleted) {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Banco excluído com sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  } else {
    return (
      <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Deseja realmente excluir este banco?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={deleteBank}>
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
};

export default BankConfirmExclude;
