import { Container } from 'react-bootstrap';
import stylesTemplate from './RegistrationFlow.module.scss';
import { useState } from 'react';
import NavBar from '../../../components/NavBar';
import FilterRegistrationRegional from '../../../components/RegistrationFlow/Regional/FilterRegistration';
import RegistrationListRegional from '../../../components/RegistrationFlow/Regional/RegistrationList';

export default function RegistrationFlowRegional() {
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  return (
    <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
      <NavBar />
      <section className={`${stylesTemplate.contentSection} ${!isMenuOpen ? stylesTemplate.menuClosed : ''}`}>
        <Container fluid>
          <div className="mb-5">
            <FilterRegistrationRegional />
          </div>
          <div>
            <RegistrationListRegional/>
          </div>
        </Container>
      </section>
    </Container>
  );
}