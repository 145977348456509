import React, { useState, useRef } from 'react';
import { Button, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import styles from '../../Register.module.scss';
import DepartmentModal from './DepartmentModal';
import DepartmentList from '../DepartmentList';

export default function DepartmentComponent() {
  const [showModal, setShowModal] = useState(false);
  const [reloadDepartmentList, setReloadDepartmentList] = useState(false);
  const keyRef = useRef(0); // Referência para a chave única do DepartmentList

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setReloadDepartmentList(true); // Atualiza a lista de departamentos após fechar o modal
  };

  const handleDepartmentListReloaded = () => {
    setReloadDepartmentList(false);
  };

  const toggleKey = () => {
    keyRef.current = keyRef.current === 0 ? 1 : 0; // Alternando entre 0 e 1
  };

  return (
    <div className="mt-5">
      <Row>
        <h1 className={`${styles.titleContent} display-6`}>Controle de Departamentos</h1>
      </Row>

      <Button className={`${styles.buttonAdd} mt-5`} onClick={handleShowModal}>
        <FontAwesomeIcon icon={faAdd} />
      </Button>

      <div className="container">
        {/* Adicionando a chave única dinâmica e passando a função de recarregamento como prop */}
        <DepartmentList key={`${reloadDepartmentList ? 'reload-triggered' : 'normal'}-${keyRef.current}`} onReload={handleDepartmentListReloaded} />
      </div>

      {/* Renderizando o modal condicionalmente */}
      {showModal && <DepartmentModal onClose={() => { handleCloseModal(); toggleKey(); }} />}
    </div>
  );
}
