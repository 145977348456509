import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

const requestClient = async (method: string, url: string, data?: any, headers?: any) => {

  try {
    const config: AxiosRequestConfig = {
      method: method,
      url: url,
      data: data,
      headers: headers,
    };

    const response: AxiosResponse = await axios(config);

    const { status, data: result } = response;

    return { status, result };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const { response } = error as AxiosError;
      if (response) {
        const { status, data: result } = response;
        return { status, result };
      }
    }
    throw error;
  }
};

export const get = async (url: string, headers?: any) => {
  return await requestClient("GET", url, undefined, headers);
};

export const post = async (url: string, data?: any, headers?: any) => {
  return await requestClient("POST", url, data, headers);
};

export const put = async (url: string, data?: any, headers?: any) => {
  return await requestClient("PUT", url, data, headers);
};

export const del = async (url: string, headers?: any) => {
  return await requestClient("DELETE", url, undefined, headers);
};

export default requestClient;