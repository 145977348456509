import React from 'react';
import styles from './Pagination.module.scss';

// Define the props for the Pagination component
interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

// Calculate total pages based on totalItems and itemsPerPage
const getTotalPages = (totalItems: number, itemsPerPage: number) => Math.ceil(totalItems / itemsPerPage);

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange
}) => {
  const totalPages = getTotalPages(totalItems, itemsPerPage);
  
  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <li
        key={number}
        className={`${styles.paginationItem} ${number === currentPage ? styles.active : ''}`}
        onClick={() => handlePageClick(number)}
      >
        {number}
      </li>
    );
  }

  return (
    <ul className={styles.pagination}>
      {paginationItems}
    </ul>
  );
};

export default Pagination;