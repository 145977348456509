import React, { useState, useEffect } from 'react';
import { Col, Row, Button, Card, Spinner, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import requestClient from '../../../../requestClient/request-cliente';
import styles from '../../AccessManagementEmployee.module.scss';

interface ContractualData {
  date_of_hiring: string;
  date_of_termination: string;
  salary: string;
  contract_type: string;
  work_card: string;
  pis_number: string;
}

const EditContractualData: React.FC<{ id: string }> = ({ id }) => {
  const [contractualData, setContractualData] = useState<ContractualData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchContractualData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || !id) {
          throw new Error('Token or User ID not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/funcionario/buscar/${id}`, null, headers);

        if (response.status === 200) {
          const { date_of_hiring, date_of_termination, salary, contract_type, work_card, pis_number } = response.result;
          setContractualData({ date_of_hiring, date_of_termination, salary, contract_type, work_card, pis_number });
        } else {
          setError('Erro ao buscar os detalhes contratuais. Por favor, tente novamente.');
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os detalhes contratuais. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchContractualData();
  }, [id]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setContractualData((prevState) => ({
      ...prevState!,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !id) {
        throw new Error('Token or User ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };

      // Remove campos vazios do JSON a ser enviado
      const filteredData = Object.fromEntries(
        Object.entries(contractualData || {}).filter(([_, value]) => value)
      );

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/funcionario/atualizar/${id}`, JSON.stringify(filteredData), headers);

      if (response.status === 200) {
        alert('Dados contratuais atualizados com sucesso');
        window.location.reload();
      } else {
        setError('Erro ao atualizar os dados contratuais. Por favor, tente novamente.');
        console.log(filteredData);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao atualizar os dados contratuais. Por favor, tente novamente.');
    }
  };

  return (
    <Col className='mt-5' sm={12} md={12}>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className={`${styles.mainTitle} display-5`}>Dados Contratuais do Funcionário</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <Form>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formDateOfHiring">
                      <Form.Label>Data de Contratação</Form.Label>
                      <Form.Control
                        type="date"
                        name="date_of_hiring"
                        value={contractualData?.date_of_hiring || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formDateOfTermination">
                      <Form.Label>Data de Desligamento</Form.Label>
                      <Form.Control
                        type="date"
                        name="date_of_termination"
                        value={contractualData?.date_of_termination || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={6}>
                    <Form.Group controlId="formSalary">
                      <Form.Label>Salário</Form.Label>
                      <Form.Control
                        type="text"
                        name="salary"
                        value={contractualData?.salary || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formContractType">
                      <Form.Label>Tipo do Contrato</Form.Label>
                      <Form.Control
                        type="text"
                        name="contract_type"
                        value={contractualData?.contract_type || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={6}>
                    <Form.Group controlId="formWorkCard">
                      <Form.Label>Carteira de Trabalho</Form.Label>
                      <Form.Control
                        type="text"
                        name="work_card"
                        value={contractualData?.work_card || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPisNumber">
                      <Form.Label>Número do PIS</Form.Label>
                      <Form.Control
                        type="text"
                        name="pis_number"
                        value={contractualData?.pis_number || ''}
                        onChange={handleInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </Button>
          </div>
          {error && <p className="text-danger mt-3">{error}</p>}
        </>
      )}
    </Col>
  );
};

export default EditContractualData;
