import { Link } from "react-router-dom";
import styles from './NotFound.module.scss'
import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

export default function NotFound(){
  const navigate = useNavigate();
  return(
    <div className={styles.mainSectionNotFound}>
      <div className="container text-light text-center mt-5">
        <h1>ERRO 404</h1>
        <h2>Página não existe</h2>
        <p>essa pagina nao existe, meu brother.</p>
        <p>
          <Button onClick={() => navigate(-1)}>
            Voltar
          </Button>
        </p>
      </div>
    </div>

  )
}