import React, { useState, useEffect, ChangeEvent } from 'react';
import { Col, Form, Button, Card, Spinner, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faSave, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import requestClient from '../../../../requestClient/request-cliente';
import { IMaskInput } from 'react-imask';
import ViewDetailsCnpj from '../../../../components/Register/ViewDetailsCnpj';
import styles from '../../AccessManagementEmployee.module.scss';

interface EmployeeDetails {
  employee_id: number;
  date_of_birth: string;
  gender: string;
  marital_status: string;
  cpf: string;
  cnpj: string;
  rg: string;
  residential_address: string;
  residential_phone: string;
  cellphone: string;
  mother_name: string;
  father_name: string;
  voter_id: string | null;
  work_card: string | null;
  pis_number: string | null;
  education: string;
  number_of_children: number;
  date_of_hiring: string;
  date_of_termination: string | null;
  salary: string;
  contract_type: string;
  full_name: string;
  user_id: {
    user_id: number;
    user_name: string;
    created_at: string;
    updated_at: string;
  };
}

const EditPersonalData: React.FC<{ id: string }> = ({ id }) => {
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const [selectedPhoto, setSelectedPhoto] = useState<File | null>(null);
  const [previewPhoto, setPreviewPhoto] = useState<string>('https://i.pinimg.com/originals/fa/60/51/fa6051d72b821cb48a8cc71d3481dfef.jpg');
  const [showCnpjDetails, setShowCnpjDetails] = useState(false);
  const [userName, setUserName] = useState<string>('');

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token || !id) {
          throw new Error('Token or Employee ID not found');
        }

        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/funcionario/buscar/${id}`, null, headers);

        if (response.status === 200) {
          setEmployeeDetails(response.result);
          // setPreviewPhoto(response.result.photo);
          setUserName(response.result.user_id.user_name);
        } else {
          setError('Erro ao buscar os detalhes do funcionário. Por favor, tente novamente.');
          alert('Sessão expirada! Favor logar novamente.')
          window.location.href = '/login';
        }
      } catch (error) {
        console.error('Error:', error);
        setError('Erro ao buscar os detalhes do funcionário. Por favor, tente novamente.');
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [id]);

  const handleViewCnpjDetails = async () => {
    const cnpj = employeeDetails?.cnpj.replace(/[^\d]/g, '');

    if (cnpj) {
      setShowCnpjDetails(true);
    } else {
      console.error('CNPJ inválido');
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (employeeDetails) {
      setEmployeeDetails(prevDetails => {
        if (!prevDetails) return null;
        return {
          ...prevDetails,
          user_id: {
            ...prevDetails.user_id,
            [name]: value
          },
          [name]: value
        };
      });
    }
  };

  const handlePhotoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedPhoto(file);
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewPhoto(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !id) {
        throw new Error('Token or Employee ID not found');
      }

      if (employeeDetails) {
        const data = {
          user_name: employeeDetails.user_id.user_name,
          cpf: employeeDetails.cpf,
          cnpj: employeeDetails.cnpj,
          rg: employeeDetails.rg,
          residential_phone: employeeDetails.residential_phone,
          marital_status: employeeDetails.marital_status,
          date_of_birth: employeeDetails.date_of_birth,
          gender: employeeDetails.gender,
          cellphone: employeeDetails.cellphone,
          father_name: employeeDetails.father_name,
          mother_name: employeeDetails.mother_name,
          education: employeeDetails.education
        };

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        };

        const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/funcionario/atualizar/${id}`, JSON.stringify(data), headers);

        if (response.status === 200) {
          alert('Funcionário atualizado com sucesso');
          window.location.reload();
        } else {
          setError('Erro ao atualizar o funcionário. Por favor, tente novamente.');
        }
      } else {
        setError('Detalhes do funcionário não encontrados');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Erro ao atualizar o funcionário. Por favor, tente novamente.');
    }
  };

  return (
    <Col className='mt-5' sm={12} md={12}>
      {loading ? (
        <p><Spinner animation="border" /></p>
      ) : (
        <>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h5 className={`${styles.mainTitle} display-5`}>Dados Pessoais - {userName}</h5>
              </div>
            </Card.Header>
            <Card.Body>
              <Form className='mt-4'>
                <div className="row align-items-center">
                  <Col xs={12} md={4} className="text-center mb-3 mb-md-0">
                    <label htmlFor="photoInput" className="employee-photo">
                      <img src={employeeDetails?.gender === 'Feminino' ? 'https://www.doorspec.com/wp-content/uploads/2018/07/female-avatar.jpg' : previewPhoto} 
                           alt="Foto do Funcionário" 
                           className='rounded-circle shadow-4-strong'
                           style={{ width: '250px', height: '250px' }}/>
                      <Button variant="outline-primary" size="sm" className="mt-2" onClick={() => document.getElementById("photoInput")?.click()}>
                        Trocar Foto
                      </Button>
                      <input id="photoInput" type="file" accept="image/*" style={{ display: 'none' }} onChange={handlePhotoChange} />
                    </label>
                  </Col>
                  <Col xs={12} md={8}>
                    <div className="row mb-3">
                      <Col sm={12} md={12}>
                        {employeeDetails?.contract_type === 'PJ' ? (
                          <>
                            <Badge bg="primary mb-3">PRESTADOR DE SERVIÇO</Badge>
                            <Form.Group className="d-flex align-items-center">
                              <Form.Control 
                                type="text" 
                                placeholder="CNPJ" 
                                name="cnpj" 
                                as={IMaskInput}
                                mask="00.000.000/0000-00"
                                value={employeeDetails?.cnpj || ''} 
                                onChange={handleInputChange} />
                              <Button variant="outline-primary" onClick={handleViewCnpjDetails} className="ms-2">
                                <FontAwesomeIcon icon={faEye} />
                              </Button>
                            </Form.Group>
                          </>
                        ) : (
                          <>
                           <Badge bg="secondary">CELETISTA</Badge>
                          </>
                        )}
                      </Col>
                    </div>
                    <ViewDetailsCnpj show={showCnpjDetails} onHide={() => setShowCnpjDetails(false)} cnpj={employeeDetails?.cnpj} />
                    <div className="row mb-2">
                      <Col sm={12} md={8}>
                        <Form.Group className='mb-3'>
                          <Form.Label>Nome do Funcionário</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder="Nome" 
                            name="user_name" 
                            value={employeeDetails?.user_id.user_name || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>CPF</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder="CPF"
                            name="cpf"
                            as={IMaskInput}
                            mask="000.000.000-00"
                            value={employeeDetails?.cpf || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>RG</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder="RG" 
                            name="rg" 
                            value={employeeDetails?.rg || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Telefone Residencial</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder="Telefone Residencial" 
                            name="residential_phone" 
                            as={IMaskInput}
                            mask="(00)00000-0000"
                            value={employeeDetails?.residential_phone || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Telefone Pessoal</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder="Telefone Pessoal" 
                            name="cellphone" 
                            as={IMaskInput}
                            mask="(00)00000-0000"
                            value={employeeDetails?.cellphone || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                    </div>
                    <div className="row mb-2">
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Data de Nascimento</Form.Label>
                          <Form.Control 
                            type="date" 
                            placeholder="Data de Nascimento" 
                            name="date_of_birth" 
                            value={employeeDetails?.date_of_birth || ''} 
                            onChange={handleInputChange} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Estado Civil</Form.Label>
                          <Form.Select name="marital_status" value={employeeDetails?.marital_status || ''} onChange={(e) => handleInputChange(e as unknown as React.ChangeEvent<HTMLInputElement>)} >
                            <option value="Solteiro" selected={employeeDetails?.marital_status === 'Solteiro'}>Solteiro</option>
                            <option value="Casado" selected={employeeDetails?.marital_status === 'Casado'}>Casado</option>
                            <option value="Divorciado" selected={employeeDetails?.marital_status === 'Divorciado'}>Divorciado</option>
                            <option value="Viúvo" selected={employeeDetails?.marital_status === 'Viúvo'}>Viúvo</option>
                            <option value="Outro" selected={employeeDetails?.marital_status === 'Outro'}>Outro</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Formação</Form.Label>
                          <Form.Select 
                            name="education" 
                            value={employeeDetails?.education || ''} 
                            onChange={(e) => handleInputChange(e as unknown as React.ChangeEvent<HTMLInputElement>)}>
                            <option value="Ensino Médio Completo">Ensino Médio Completo</option>
                            <option value="Ensino Superior Completo">Ensino Superior Completo</option>
                            <option value="Ensino Superior Incompleto">Ensino Superior Incompleto</option>
                            <option value="Mestrado">Mestrado</option>
                            <option value="Doutorado">Doutorado</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </div>
                    <div className='row mb-3'>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Gênero</Form.Label>
                          <Form.Select name="gender" value={employeeDetails?.gender || ''} onChange={(e) => handleInputChange(e as unknown as React.ChangeEvent<HTMLInputElement>)} >
                            <option value="Masculino" selected={employeeDetails?.gender === 'Masculino'}>Masculino</option>
                            <option value="Feminino" selected={employeeDetails?.gender === 'Feminino'}>Feminino</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Nome do Pai</Form.Label>
                          <Form.Control 
                            type='text' 
                            className='form-control'
                            name='father_name'
                            onChange={handleInputChange}
                            value={employeeDetails?.father_name || ''} />
                        </Form.Group>
                      </Col>
                      <Col sm={12} md={4}>
                        <Form.Group>
                          <Form.Label>Nome da Mãe</Form.Label>
                          <Form.Control 
                            type='text' 
                            className='form-control'
                            name='mother_name'
                            onChange={handleInputChange}
                            value={employeeDetails?.mother_name || ''} />
                        </Form.Group>
                      </Col>
                    </div>
                  </Col>
                </div>
              </Form>
            </Card.Body>
          </Card>
          <div className="d-flex justify-content-center mt-4">
            <Button variant="primary" onClick={handleSave}>
              <FontAwesomeIcon icon={faSave} /> Salvar
            </Button>
          </div>
          {error && <p className="text-danger mt-3">{error}</p>}
        </>
      )}
    </Col>
  );
};

export default EditPersonalData;
