import { Table } from 'react-bootstrap';

// Importe a interface IData
import IData  from '../../interfaces/IDataCadastro';

interface CustomTableProps {
  headers: string[];
  data: IData[]; // Altere o tipo dos dados para IData[]
}

const CustomTable: React.FC<CustomTableProps> = ({ headers, data }) => {
  // Função para converter os dados no formato da interface IData para o formato esperado pelo componente
  const convertDataForTable = (data: IData[]): (string | number | JSX.Element)[][] => {
    return data.map(item => {
      // Extrai os campos específicos que você deseja exibir na tabela
      const rowData: (string | number)[] = [
        item.user?.user_id ?? '', // Exemplo: acessa o campo user_id de cada objeto IData
        item.user?.user_name ?? '',
        item.user?.email ?? '',
        item.user?.user_password ?? '',
        item.user?.created_at ?? '',
        item.user?.updated_at ?? ''
      ];
      return rowData;
    });
  };

  // Converte os dados para o formato esperado pelo componente CustomTable
  const convertedData = convertDataForTable(data);

  return (
    <Table bordered hover responsive>
      <thead>
        <tr>
          {headers.map(header => (
            <th key={header}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {convertedData.map((rowData, rowIndex) => (
          <tr key={rowIndex}>
            {rowData.map((cellData, cellIndex) => (
              <td key={cellIndex}>
                {/* valida se cellData é uma string ou um número */}
                {typeof cellData === 'string' || typeof cellData === 'number' ? (
                  cellData
                ) : (
                  cellData
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default CustomTable;
