import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import requestClient from '../../../../requestClient/request-cliente';

interface EditBankModalProps {
  bankId: number | null;
  onClose: () => void;
  onUpdate: () => void;
  onError: (errorMessage: string) => void;
}

const BankEditModal: React.FC<EditBankModalProps> = ({ bankId, onClose, onUpdate, onError }) => {
  const [bankName, setBankName] = useState<string>('');
  const [bankNumber, setBankNumber] = useState<string>('');
  const [updateSuccess, setUpdateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (bankId) {
      fetchBankName();
    }
  }, [bankId]);

  const fetchBankName = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token || !bankId) {
        throw new Error('Token or bank ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('get', `http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/buscar-usuario/${bankId}`, null, headers);

      if (response.status === 200) {
        setBankName(response.result.name);
        setBankNumber(response.result.number_bank);
      } else {
        throw new Error('Erro ao buscar o nome do banco. Por favor, tente novamente.');
      }
    } catch (error:any) {
      console.error('Error:', error);
      onError(error.message || 'Erro ao buscar o nome do banco. Por favor, tente novamente.');
    }
  };

  const handleBankNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankName(event.target.value);
  };
  const handleBankNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankNumber(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const token = localStorage.getItem('token');
      if (!token || !bankId) {
        throw new Error('Token or bank ID not found');
      }

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const response = await requestClient('put', `http://webservices.gfttech.com.br/api/v1/dados-bancario/banco/atualizar/${bankId}`, { name: bankName , number_bank: bankNumber}, headers);

      if (response.status === 200) {
        onUpdate();
        setUpdateSuccess(true);
      } else {
        if (response.result && response.result.message) {
          throw new Error(response.result.message);
        } else {
          throw new Error('Erro ao editar o banco. Por favor, tente novamente.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        onError(error.message || 'Erro ao editar o banco. Por favor, tente novamente.');
      } else {
        onError('Erro ao editar o banco. Por favor, tente novamente.');
      }
    }
  };

  const handleClose = () => {
    onUpdate();
    onClose();
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateSuccess && (
          <div className="alert alert-success" role="alert">
            Banco atualizado com sucesso!
          </div>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formBankName" className='mb-5'>
            <Row>
              <Col xs={8}>
                <Form.Control 
                  type="text" 
                  placeholder="Digite o nome do banco" 
                  value={bankName} 
                  onChange={handleBankNameChange} 
                  required 
                />
              </Col>
              <Col xs={4}>
                <Form.Control 
                    type="text" 
                    placeholder="Digite o numero do banco" 
                    value={bankNumber} 
                    onChange={handleBankNumberChange} 
                    required 
                  />
              </Col>
            </Row>
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button variant="primary" type="submit" className='me-2'>
              Salvar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancelar
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BankEditModal;
