import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding, faUserTie, faMapMarkerAlt, faCalendarAlt, faChartLine, faIdBadge, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import NavBar from "../../components/NavBar";
import stylesTemplate from "./PartnerPanel.module.scss";
import Production from "../../components/PartnerPanelComponents/Production";
import AvailableConventions from "../../components/PartnerPanelComponents/AvailableConventions";
import Retention from "../../components/PartnerPanelComponents/Retention";
import NotFoundPartner from "../../components/PartnerPanelComponents/NotFoundPartner";
import { useEffect, useState } from "react";
import FilterPartnerPanel from "../../components/PartnerPanelComponents/FilterPartnerPanel";
import Benefits from "../../components/PartnerPanelComponents/Benefits";

function truncateText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '.';
  }
  return text;
}

function formatDate(dateString: string) {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

export default function PartnerPanel() {
  const { codPartner } = useParams();
  const [partnerData, setPartnerData] = useState({
    codigo: "",
    nome: "", 
    filial: "",
    gerenteComercial: "",
    regional: "",
    superintendente: "",
    dataCadastro: "",
    limiteFisico: "",
    libComissao: "",
    tipo: "",
    uf: "",
    prodMesAtual: 0,
    prodMesAnterior: 0,
    quantidade_dias_sem_digitar: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const handleMenuToggle = (isOpen: boolean) => {
    setIsMenuOpen(isOpen);
  };

  useEffect(() => {
    if (!codPartner) {
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setIsError(false);
    fetch(`http://bi.gfttech.com.br/api/v1/atendimento/agentes?codProdutorVenda=${codPartner}`)
      .then(response => {
        if (response.status === 404) {
          throw new Error("404");
        }
        return response.json();
      })
      .then(data => {
        const partnerInfo = data[0];

        setPartnerData({
          codigo: partnerInfo.codigo_parceiro,
          nome: partnerInfo.nome_parceiro,
          filial: partnerInfo.filial,
          gerenteComercial: partnerInfo.gerente,
          regional: partnerInfo.regional,
          superintendente: partnerInfo.superintendente,
          dataCadastro: formatDate(partnerInfo.data_cadastro),
          limiteFisico: `${partnerInfo.dias_fisico} dias`,
          libComissao: partnerInfo.ind_comissao_bloqueada === 0 ? 'Liberada' : 'Bloqueada',
          tipo: partnerInfo.tipo_parceiro,
          uf: partnerInfo.uf,
          prodMesAtual: Number(partnerInfo.producao_mes_atual),
          prodMesAnterior: Number(partnerInfo.producao_mes_anterior),
          quantidade_dias_sem_digitar: Number(partnerInfo.quantidade_dias_sem_digitar)
        });
        setIsLoading(false);
      })
      .catch(error => {
        if (error.message === "404") {
          setIsError(true);
        }
        setIsLoading(false);
      });
  }, [codPartner]);

  return (
    <Container fluid className="p-0" style={{ overflowX: 'hidden' }}>
      <NavBar />
      <section className={`${stylesTemplate.contentSection} ${!isMenuOpen ? stylesTemplate.menuClosed : ''}`}>
        <Container>
          <FilterPartnerPanel />
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
              <Spinner animation="border" />
            </div>
          ) : !codPartner ? null : isError ? (
            <NotFoundPartner />
          ) : (
            <>
              <Card className="shadow-sm mb-4">
                <Card.Header className={`${stylesTemplate.cardHeader}`}>
                  <h3 className="m-0">{partnerData.nome}</h3>
                </Card.Header>
                <Card.Body className={`${stylesTemplate.cardBody}`}>
                  <Row>
                    <Col xs={12} md={4} className={`${stylesTemplate.partnerInfo}`}>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faUser} className={`${stylesTemplate.textPrimary} me-2 `}/>
                          <strong>Código:</strong> {partnerData.codigo}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faBuilding} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Filial:</strong> {partnerData.filial}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faChartLine} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Limite Físico:</strong> {partnerData.limiteFisico}
                        </li>
                      </ul>
                    </Col>
                    <Col xs={12} md={4} className={`${stylesTemplate.partnerInfo}`}>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faUserTie} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Gerente:</strong> {truncateText(partnerData.gerenteComercial, 20)}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Regional:</strong> {truncateText(partnerData.regional, 20)}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faUserTie} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Super:</strong> {truncateText(partnerData.superintendente, 20)}
                        </li>
                      </ul>
                    </Col>
                    <Col xs={12} md={4} className={`${stylesTemplate.partnerInfo}`}>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faCalendarAlt} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Data de Cadastro:</strong> {partnerData.dataCadastro}
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faDollarSign} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Comissão:</strong>
                          <span className={partnerData.libComissao === 'Bloqueada' ? 'text-danger' : ''}> {partnerData.libComissao}</span>
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faIdBadge} className={`${stylesTemplate.textPrimary} me-2 `} />
                          <strong>Tipo:</strong> {partnerData.tipo}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Production 
                prodMesAtual={partnerData.prodMesAtual} 
                prodMesAnterior={partnerData.prodMesAnterior} 
                quantidade_dias_sem_digitar={partnerData.quantidade_dias_sem_digitar} 
                codPartner={codPartner}
              />
              <AvailableConventions initialUF={partnerData.uf} />
              <Benefits codPartner={codPartner}/>
              <Retention codPartner={codPartner} />
            </>
          )}
        </Container>
      </section>
    </Container>
  );
}