import React, { useState } from 'react';
import { Badge, ListGroup, Modal } from 'react-bootstrap';
import styles from './ModalHistoric.module.scss';
import Pagination from '../../Pagination';

interface HistoryData {
  date: string;
  event: string;
  details: string;
}

const ModalHistoric: React.FC<{ show: boolean; onHide: () => void; }> = ({ show, onHide }) => {
  // Dados de exemplo
  const historyData: Array<HistoryData> = [
    { date: '08/08/2024 16:15', event: 'TRIAGEM CADASTRO', details: 'Analise documental concluida com sucesso!' },
    { date: '08/08/2024 16:10', event: 'APROVAÇÃO REGIONAL', details: 'Parceiro aprovado, já conheço das antiga. É gente boa!' },
    { date: '08/08/2024 16:00', event: 'Inicio do Pré-Cadastro', details: '' },
    { date: '07/08/2024 15:45', event: 'Atualização de Documentos', details: 'Documentos atualizados com sucesso.' },
    { date: '07/08/2024 14:30', event: 'Revisão de Dados', details: 'Dados revisados e confirmados.' },
    { date: '06/08/2024 13:20', event: 'Solicitação de Informações', details: 'Informações solicitadas ao parceiro.' },
    { date: '06/08/2024 12:10', event: 'Aprovação de Cadastro', details: 'Cadastro aprovado e finalizado.' },
    { date: '05/08/2024 11:00', event: 'Início do Processo', details: 'Início do processo de cadastro.' },
    { date: '05/08/2024 10:30', event: 'Verificação de Dados', details: 'Dados verificados e confirmados.' },
    { date: '04/08/2024 09:15', event: 'Recebimento de Documentos', details: 'Documentos recebidos e analisados.' },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  // Calcula o índice inicial e final dos itens a serem exibidos na página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = historyData.slice(indexOfFirstItem, indexOfLastItem);

  // Calcula o número total de páginas
  const totalPages = Math.ceil(historyData.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Modal show={show} size="lg" onHide={onHide} className={styles.customModal}>
      <Modal.Header closeButton className={styles.backgroundModal}>
        <Modal.Title className={styles.modalTitle}>Histórico do Cadastro</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${styles.backgroundModal} ${styles.bodyModal}`}>
        <ListGroup className={`${styles.bodyModal} mt-3 mb-5`}>
          {currentItems.map((item, index) => (
            <ListGroup.Item
              key={index}
              as="li"
              className="d-flex justify-content-between align-items-start"
            >
              <div className="ms-2 me-auto">
                <div className="mt-2 fw-bold mb-2">{item.event} - {item.date}</div>
                {item.details}
              </div>
              <Badge className={styles.badgeHistoric} bg="primary" pill>
                USER.{index + 1}
              </Badge>
            </ListGroup.Item>
          ))}
        </ListGroup>
        <Pagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={historyData.length}
          onPageChange={handlePageChange}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ModalHistoric;