import FormPreRegistration from './FormPreRegistration';
import styles from './PreRegistration.module.scss';
import logo from '../../assets/images/main_logo_gft.png';
import { useParams } from 'react-router-dom';

export default function PreRegistration() {
    const { codManager } = useParams<{ codManager?: string }>();

    return (
        <>
          <div className={`${styles.mainSectionRegistration} mb-5`}>
              <div className={`${styles.containerRegistration} p-5 col-sm-12 col-md-6 col-lg-4 mx-auto`}>
                  <header className={`${styles.headerRegistration} d-flex align-items-center mb-5`}>
                      <img src={logo} alt="Logo" />
                      <h5 className='display-6 text-center flex-grow-1'>Pré-Cadastro</h5>
                  </header>
                  <FormPreRegistration codManager={codManager}/>
              </div>
          </div>
        </>
    );
}
