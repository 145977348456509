import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";


export default function DataExportPdf(){
  return(
    <>
      <Button
        className="bg-secondary me-2"
      >
        <FontAwesomeIcon icon={faFilePdf} fontSize={23}/>
      </Button>
    </>
  )
}